/* tslint:disable */
import { Region } from './region';
export class City { 
  id: number;
  regionid?: number;
  countryid?: number;
  name?: string;
  date?: string;
}

export interface City {
  id: number;
  regionid?: number;
  countryid?: number;
  name?: string;
  regionname?: string;
  countryname?: string;
  date?: string;
}

export interface Cities {
  items: City[];
  total_count: number;
}
export interface CityForCreation {
  id: number;
  regionid?: number;
  name?: string;
  date?: string;
}
export interface CityForUpdate {
  id: number;
  regionid?: number;
  name?: string;
  date?: string;
}
export interface CityForDelete {
    id: number;
    apiKey?: string;
}