/* tslint:disable */

/**
 * littlebigroad.step
 */

export class Step { 
  hotelid?: number;
  id: number;
  kind?: string;
  airportid?: number;
  airportname?: string;
  nnights?: number;
  commandid?: number;
  hotelname?: string;
  address?: string;
  stepindex?: number;
  date?: string;
  newcityname?: string;
  cityid?: number;
}

export interface Step {
  hotelid?: number;
  id: number;
  kind?: string;
  airportid?: number;
  airportname?: string;
  destinationairportid?: number;
  destinationairportname?: string;
  nnights?: number;
  commandid?: number;
  hotelname?: string;
  address?: string;
  stepindex?: number;
  date?: string;
  newcityname?: string;
  cityid?: number;
}

export interface Steps {
  items?: Step[];
  total_count?: number;
}
export interface StepForCreation {
  hotelid?: number;
  id: number;
  kind?: string;
  airportid?: number;
  airportname?: string;
  destinationairportid?: number;
  destinationairportname?: string;
  nnights?: number;
  commandid?: number;
  hotelname?: string;
  address?: string;
  stepindex?: number;
  date?: string;
  newcityname?: string;
  cityid?: number;
}

export interface StepForUpdate {
  hotelid?: number;
  id: number;
  kind?: string;
  airportid?: number;
  airportname?: string;
  destinationairportid?: number;
  destinationairportname?: string;
  nnights?: number;
  commandid?: number;
  hotelname?: string;
  address?: string;
  stepindex?: number;
  date?: string;
  newcityname?: string;
  cityid?: number;
  }
export interface StepForDelete {
    id: number;
    apiKey?: string;
}