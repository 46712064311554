/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  Airport,
  Airports,
  AirportForCreation,
  AirportForUpdate,
  AirportForDelete,
} from "../_models/airport";

@Injectable({
  providedIn: "root",
})
class AirportService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all airports
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Airports>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/airport?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Airports>;
      })
    );
  }

  /**
   * Returns all airports
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Airports)
    );
  }

  /**
   * Returns a single airport
   * @param airportId ID of pet to return
   * @return successful operation
   */
  getAirportByIdResponse(
    airportId: number
  ): __Observable<__StrictHttpResponse<Airport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/airport/${airportId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Airport>;
      })
    );
  }
  /**
   * Returns a single airport
   * @param airportId ID of pet to return
   * @return successful operation
   */
  getAirportById(airportId: number): __Observable<Airport> {
    return this.getAirportByIdResponse(airportId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Airport)
    );
  }

  /**
   * @param params The `AirportService.UpdateAirportParams` containing the following parameters:
   *
   * - `airportname`: airportname of the airport
   * - `name`: name of the airport
   * - `email`: email of the airport
   * - `password`: password of the airport
   * - `companyId`: Id from the company of the airport
   *
   *
   */
  createAirportResponse(
    params: AirportForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/airport`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AirportService.UpdateAirportParams` containing the following parameters:
   *
   * - `airportId`: ID of airport that needs to be updated
   *
   * - `status`: Updated status of the airport
   *
   * - `name`: Updated name of the airport
   */
  updateAirportResponse(
    params: AirportForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/airport/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AirportService.UpdateAirportParams` containing the following parameters:
   *
   * - `id`: ID of airport that needs to be updated
   *
   * - `status`: Updated status of the airport
   *
   * - `name`: Updated name of the airport
   */
  updateAirport(params: AirportForUpdate): __Observable<null> {
    return this.updateAirportResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `AirportService.DeleteAirportParams` containing the following parameters:
   *
   * - `id`: Airport id to delete
   *
   * - `api_key`:
   */
  deleteAirportResponse(
    params: AirportForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/airport/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AirportService.DeleteAirportParams` containing the following parameters:
   *
   * - `id`: Airport id to delete
   *
   * - `api_key`:
   */
  deleteAirport(params: AirportForDelete): __Observable<null> {
    return this.deleteAirportResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  tableService() {
    return "airport";
  }

  columnsAirport() {
    return [
      {
        columnDef: "airport.id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "airport.name",
        header: "LISTS.AIRPORT_NAME",
        cell: (element: any) => `${element.name}`,
      },
    ];
  }
}

module AirportService {}

export { AirportService };
