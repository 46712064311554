/* tslint:disable */
export class Company { 
  phone?: string;
  id: number;
  mapgabaritid: number;
  name?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  tripgabaritid: number;
  fax?: string;
  email?: string;
  datekind?: string;
  kind?: string;
  exclusivecountryids?: string;
  date?: string;
  commandlangageid: number;
  emailreport?: string;
}

export interface Company {
  id: number;
  phone?: string;
  mapgabaritid: number;
  name?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  tripgabaritid: number;
  fax?: string;
  email?: string;
  datekind?: string;
  kind?: string;
  exclusivecountryids?: string;
  date?: string;
  commandlangageid: number;
  emailreport?: string;
}

export interface Companies {
  items: Company[];
  total_count: number;
}

export interface CompanyForCreation {
  id: number;
  phone?: string;
  mapgabaritid: number;
  name?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  tripgabaritid: number;
  fax?: string;
  email?: string;
  datekind?: string;
  kind?: string;
  exclusivecountryids?: string;
  date?: string;
  commandlangageid: number;
  emailreport?: string;
}
export interface CompanyForUpdate {
  id: number;
  phone?: string;
  mapgabaritid: number;
  name?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  tripgabaritid: number;
  fax?: string;
  email?: string;
  datekind?: string;
  kind?: string;
  exclusivecountryids?: string;
  date?: string;
  commandlangageid: number;
  emailreport?: string;
}
export interface CompanyForDelete {
    id: number;
    apiKey?: string;
}
