/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  Trip,
  Trips,
  TripForCreation,
  TripForUpdate,
  TripForDelete,
} from "../_models/trip";

@Injectable({
  providedIn: "root",
})
class TripService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all trips
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Trips>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/trip?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Trips>;
      })
    );
  }

  /**
   * Returns all trips
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Trips)
    );
  }

  /**
   * Returns a single trip
   * @param tripId ID of pet to return
   * @return successful operation
   */
  getTripByIdResponse(
    tripId: number
  ): __Observable<__StrictHttpResponse<Trip>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/trip/${tripId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Trip>;
      })
    );
  }
  /**
   * Returns a single trip
   * @param tripId ID of pet to return
   * @return successful operation
   */
  getTripById(tripId: number): __Observable<Trip> {
    return this.getTripByIdResponse(tripId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Trip)
    );
  }

  /**
   * @param params The `TripService.UpdateTripParams` containing the following parameters:
   *
   * - `tripname`: tripname of the trip
   * - `name`: name of the trip
   * - `email`: email of the trip
   * - `password`: password of the trip
   * - `companyId`: Id from the company of the trip
   *
   *
   */
  createTripResponse(
    params: TripForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/trip`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TripService.UpdateTripParams` containing the following parameters:
   *
   * - `tripId`: ID of trip that needs to be updated
   *
   * - `status`: Updated status of the trip
   *
   * - `name`: Updated name of the trip
   */
  updateTripResponse(
    params: TripForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/trip/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TripService.UpdateTripParams` containing the following parameters:
   *
   * - `id`: ID of trip that needs to be updated
   *
   * - `status`: Updated status of the trip
   *
   * - `name`: Updated name of the trip
   */
  updateTrip(params: TripForUpdate): __Observable<null> {
    return this.updateTripResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `TripService.DeleteTripParams` containing the following parameters:
   *
   * - `id`: Trip id to delete
   *
   * - `api_key`:
   */
  deleteTripResponse(
    params: TripForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/trip/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TripService.DeleteTripParams` containing the following parameters:
   *
   * - `id`: Trip id to delete
   *
   * - `api_key`:
   */
  deleteTrip(params: TripForDelete): __Observable<null> {
    return this.deleteTripResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  tableService() {
    return "trip";
  }


  columnsTrip() {
    return [
      {
        columnDef: "trip.id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "CONCAT(IF(trip.startidkind='city', CONCAT('V : ', startcity.name), CONCAT('A : ', startairport.name)),' -> ',IF(trip.endidkind='city', CONCAT('V : ', endcity.name), CONCAT('A : ', endairport.name)))",
        header: "LISTS.NAME",
        cell: (element: any) => `${element.cname}`,
      },
    ];
  }
}

module TripService {}

export { TripService };
