import { Component, OnInit, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "xxx-noname-dialog",
  templateUrl: "./noname-dialog.component.html",
  styleUrls: ["./noname-dialog.component.css"],
})
export class NonameDialogComponent implements OnInit {
  access_role: String;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService
  ) {
    translate.addLangs(["en", "fr"]);
    this.access_role = localStorage.getItem("access_role");
    if (localStorage.getItem("locale")) {
      const browserLang = localStorage.getItem("locale");
      translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
    } else {
      localStorage.setItem("locale", "en");
      translate.setDefaultLang("en");
    }
  }
  newNoname;

  ngOnInit(): void {
    this.newNoname = this.data.newNoname;
  }
}
