import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin.component';

const routes: Routes = [
  { path: '', component: AdminComponent }, 
  
  {path: 'company-list', loadChildren: () => import('./company-list/company-list.module').then(m => m.CompanyListModule) },
  { path: 'company-form', loadChildren: () => import('./company-form/company-form.module').then(m => m.CompanyFormModule) },
  { path: 'user-list', loadChildren: () => import('./user-list/user-list.module').then(m => m.UserListModule) }, 
  { path: 'user-form', loadChildren: () => import('./user-form/user-form.module').then(m => m.UserFormModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
