/**
 * Littlebigroad API
 * API du projet Littlebigroad
 *
 * OpenAPI spec version: 0.0.1
 * Contact: apiteam@littlebigroad.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { City } from './city';

export class Airport {
  id: number;
  cityid?: number;
  regionid?: number;
  countryid?: number;
  name?: string;
  cityname?: string;
  regionname?: string;
  countryname?: string;
  date?: Date;
}

export interface Airport {
  id: number;
  cityid?: number;
  regionid?: number;
  countryid?: number;
  name?: string;
  cityname?: string;
  regionname?: string;
  countryname?: string;
  date?: Date;
}

export interface Airports {
  items: Airport[];
  total_count: number;
}
export interface AirportForCreation {
  id: number;
  cityid?: number;
  name?: string;
  date?: Date;
}
export interface AirportForUpdate {
  id: number;
  name?: string;
  cityid?: number;
}
export interface AirportForDelete {
  id: number;
  apiKey?: string;
}