import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ElectronService } from "@app/_services/electron.service";

@Component({
  selector: "xxx-publish-dialog",
  templateUrl: "./publish-dialog.component.html",
  styleUrls: ["./publish-dialog.component.css"],
})

export class PublishDialogComponent implements OnInit {
  data: any;
  access_role: String;
  constructor(
    private electronService: ElectronService,
    public dialogRef: MatDialogRef<PublishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialdata: any,
  ) {
    console.log("MAT_DIALOG_DATA", dialdata);
    this.data = dialdata;
  }

  ngOnInit(): void {
  }

  runIndesign() {
      console.log("runIndesign");
      this.electronService.renderer().send("runIndesign", this.data);
  }
}
