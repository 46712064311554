/* tslint:disable */
import { Country } from './country';

export class Region { 
  id: number;
  countryid?: number;
  name?: string;
  date?: string;
}

export interface Region {
  id: number;
  countryid?: number;
  name?: string;
  date?: string;
}

export interface Regions {
  items: Region[];
  total_count: number;
}
export interface RegionForCreation {
  id: number;
  countryid?: number;
  name?: string;
  date?: string;
}
export interface RegionForUpdate {
  id: number;
  countryid?: number;
  name?: string;
  date?: string;
  }
export interface RegionForDelete {
    id: number;
    apiKey?: string;
}