import {
  ApplicationRef,
  Component,
  OnInit,
  Inject,
  NgZone,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ElectronService } from "@app/_services/electron.service";
import { CommandService } from "@app/_services/command.service";

@Component({
  selector: "xxx-salesforce-dialog",
  templateUrl: "./salesforce-dialog.component.html",
  styleUrls: ["./salesforce-dialog.component.css"],
})
export class SalesforceDialogComponent implements OnInit {
  statuslog: any;
  data: any;
  access_role: String;
  constructor(
    private electronService: ElectronService,
    private commandService: CommandService,
    public dialogRef: MatDialogRef<SalesforceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialdata: any,
    public applicationRef: ApplicationRef,
  ) {
    console.log("MAT_DIALOG_DATA", dialdata);
    this.data = dialdata;
  }

  ngOnInit(): void {
  }

  sendSalesforce() {
      this.electronService.renderer().send("sendSalesforce", this.data);
  }
}
