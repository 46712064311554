/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  Company,
  Companies,
  CompanyForCreation,
  CompanyForUpdate,
  CompanyForDelete,
} from "../_models/company";

@Injectable({
  providedIn: "root",
})
class CompanyService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all companies
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Companies>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    
    str = str.replace(/agency/g, "company");

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/company?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Companies>;
      })
    );
  }

  /**
   * Returns all companies
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Companies)
    );
  }

  /**
   * Returns a single company
   * @param companyId ID of pet to return
   * @return successful operation
   */
  getCompanyByIdResponse(
    companyId: number
  ): __Observable<__StrictHttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/company/${companyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Company>;
      })
    );
  }
  /**
   * Returns a single company
   * @param companyId ID of pet to return
   * @return successful operation
   */
  getCompanyById(companyId: number): __Observable<Company> {
    return this.getCompanyByIdResponse(companyId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Company)
    );
  }

  /**
   * @param params The `CompanyService.UpdateCompanyParams` containing the following parameters:
   *
   * - `companyname`: companyname of the company
   * - `name`: name of the company
   * - `email`: email of the company
   * - `password`: password of the company
   * - `companyId`: Id from the company of the company
   *
   *
   */
  createCompanyResponse(
    params: CompanyForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/company`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CompanyService.UpdateCompanyParams` containing the following parameters:
   *
   * - `companyId`: ID of company that needs to be updated
   *
   * - `status`: Updated status of the company
   *
   * - `name`: Updated name of the company
   */
  updateCompanyResponse(
    params: CompanyForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/company/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CompanyService.UpdateCompanyParams` containing the following parameters:
   *
   * - `id`: ID of company that needs to be updated
   *
   * - `status`: Updated status of the company
   *
   * - `name`: Updated name of the company
   */
  updateCompany(params: CompanyForUpdate): __Observable<null> {
    return this.updateCompanyResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `CompanyService.DeleteCompanyParams` containing the following parameters:
   *
   * - `id`: Company id to delete
   *
   * - `api_key`:
   */
  deleteCompanyResponse(
    params: CompanyForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/company/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CompanyService.DeleteCompanyParams` containing the following parameters:
   *
   * - `id`: Company id to delete
   *
   * - `api_key`:
   */
  deleteCompany(params: CompanyForDelete): __Observable<null> {
    return this.deleteCompanyResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  tableService() {
    return "company";
  }

  columnsCompany() {
    return [
      {
        columnDef: "company.id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "company.name",
        header: "LISTS.NAME",
        cell: (element: any) => `${element.name}`,
      },
      {
        columnDef: "company.tripgabaritid",
        header: "LISTS.TRIP_GAB_NAME",
        cell: (element: any) => `${element.tripgabaritname}`,
      },
      {
        columnDef: "company.mapgabaritid",
        header: "LISTS.MAP_GAB_NAME",
        cell: (element: any) => `${element.mapgabaritname}`,
      },
      {
        columnDef: "company.kind",
        header: "LISTS.KIND",
        cell: (element: any) => `${element.kind}`,
      },
      // { columnDef: 'datekind',     header: 'datekind',   cell: (element: any) => `${element.datekind}`     },
      {
        columnDef: "company.exclusivecountryids",
        header: "LISTS.EXCLU_COUNTRY_IDS",
        cell: (element: any) => `${element.exclusivecountryids}`,
      },
    ];
  }
}

module CompanyService {}

export { CompanyService };
