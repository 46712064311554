/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  Template,
  Templates,
  TemplateForCreation,
  TemplateForUpdate,
  TemplateForDelete,
} from "../_models/template";

@Injectable({
  providedIn: "root",
})
class TemplateService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all countries
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Templates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/template?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Templates>;
      })
    );
  }

  /**
   * Returns all countries
   */
  getAll(
    search: any = { where: [], direction: null, sort: null, limit: "0,10" }
  ) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Templates)
    );
  }

  /**
   * Returns a single template
   * @param templateId ID of pet to return
   * @return successful operation
   */
  getTemplateByIdResponse(
    templateId: number
  ): __Observable<__StrictHttpResponse<Template>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/template/${templateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Template>;
      })
    );
  }
  /**
   * Returns a single template
   * @param templateId ID of pet to return
   * @return successful operation
   */
  getTemplateById(templateId: number): __Observable<Template> {
    return this.getTemplateByIdResponse(templateId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Template)
    );
  }

  /**
   * @param params The `TemplateService.UpdateTemplateParams` containing the following parameters:
   *
   * - `templatename`: templatename of the template
   * - `name`: name of the template
   * - `email`: email of the template
   * - `password`: password of the template
   * - `templateId`: Id from the template of the template
   *
   *
   */
  createTemplateResponse(
    params: TemplateForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/template`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TemplateService.UpdateTemplateParams` containing the following parameters:
   *
   * - `templateId`: ID of template that needs to be updated
   *
   * - `status`: Updated status of the template
   *
   * - `name`: Updated name of the template
   */
  updateTemplateResponse(
    params: TemplateForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/template/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TemplateService.UpdateTemplateParams` containing the following parameters:
   *
   * - `id`: ID of template that needs to be updated
   *
   * - `status`: Updated status of the template
   *
   * - `name`: Updated name of the template
   */
  updateTemplate(params: TemplateForUpdate): __Observable<null> {
    return this.updateTemplateResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `TemplateService.DeleteTemplateParams` containing the following parameters:
   *
   * - `id`: Template id to delete
   *
   * - `api_key`:
   */
  deleteTemplateResponse(
    params: TemplateForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/template/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TemplateService.DeleteTemplateParams` containing the following parameters:
   *
   * - `id`: Template id to delete
   *
   * - `api_key`:
   */
  deleteTemplate(params: TemplateForDelete): __Observable<null> {
    return this.deleteTemplateResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  tableService() {
    return "template";
  }
  columnsTemplate() {
    return [
      {
        columnDef: "id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "name",
        header: "LISTS.NAME",
        cell: (element: any) => `${element.name}`,
      },
    ];
  }
}

module TemplateService {}

export { TemplateService };
