/* tslint:disable */

/**
 * littlebigroad.user
 */
import { Role } from "./role";

export class User { 
  id: number;
  username?: string;
  password?: string;
  email?: string;
  name?: string;
  lastName?: string;
  role?: Role;
  datakind?: Role;
  companyid?: number;
  companyname?: string;
  date?: Date;
  token?: string;
}

export interface User {
  id: number;
  username?: string;
  password?: string;
  email?: string;
  name?: string;
  lastName?: string;
  role?: Role;
  datakind?: Role;
  companyid?: number;
  companyname?: string;
  date?: Date;
  token?: string;
}

export interface Users {
  items?: User[];
  total_count?: number;
}
export interface UserForCreation {
  id?: number;
  username?: string;
  password?: string;
  email?: string;
  name?: string;
  lastName?: string;
  role?: Role;
  datakind?: Role;
  companyid?: number;
  companyname?: string;
  date?: Date;
  token?: string;
}
export interface UserForUpdate {
  id?: number;
  username?: string;
  password?: string;
  email?: string;
  name?: string;
  lastName?: string;
  role?: Role;
  datakind?: Role;
  companyid?: number;
  companyname?: string;
  date?: Date;
  token?: string;
  }
export interface UserForDelete {
    id: number;
    apiKey?: string;
}