/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  User,
  Users,
  UserForCreation,
  UserForUpdate,
  UserForDelete,
} from "../_models/user";

@Injectable({
  providedIn: "root",
})
class UserService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all users
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Users>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/user?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Users>;
      })
    );
  }

  /**
   * Returns all users
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Users)
    );
  }

  /**
   * Returns a single user
   * @param userId ID of pet to return
   * @return successful operation
   */
  getUserByIdResponse(
    userId: number
  ): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/user/${userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Returns a single user
   * @param userId ID of pet to return
   * @return successful operation
   */
  getUserById(userId: number): __Observable<User> {
    return this.getUserByIdResponse(userId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as User)
    );
  }

  /**
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `username`: username of the user
   * - `name`: name of the user
   * - `email`: email of the user
   * - `password`: password of the user
   * - `companyId`: Id from the company of the user
   *
   *
   */
  createUserResponse(
    params: UserForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/user`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `userId`: ID of user that needs to be updated
   *
   * - `status`: Updated status of the user
   *
   * - `name`: Updated name of the user
   */
  updateUserResponse(
    params: UserForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/user/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `id`: ID of user that needs to be updated
   *
   * - `status`: Updated status of the user
   *
   * - `name`: Updated name of the user
   */
  updateUser(params: UserForUpdate): __Observable<null> {
    return this.updateUserResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `UserService.DeleteUserParams` containing the following parameters:
   *
   * - `id`: User id to delete
   *
   * - `api_key`:
   */
  deleteUserResponse(
    params: UserForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/user/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.DeleteUserParams` containing the following parameters:
   *
   * - `id`: User id to delete
   *
   * - `api_key`:
   */
  deleteUser(params: UserForDelete): __Observable<null> {
    return this.deleteUserResponse(params).pipe(__map((_r) => _r.body as null));
  }

  tableService() {
    return "user";
  }

  columnsUser() {
    return [
      {
        columnDef: "user.id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "username",
        header: "LISTS.USER_NAME",
        cell: (element: any) => `${element.username}`,
      },
      // { columnDef: 'password',   header: 'password', cell: (element: any) => `${element.password}`   },
      {
        columnDef: "user.email",
        header: "LISTS.EMAIL",
        cell: (element: any) => `${element.email}`,
      },
      {
        columnDef: "user.name",
        header: "LISTS.NAME",
        cell: (element: any) => `${element.name}`,
      },
      // { columnDef: 'lastName',   header: 'lastName', cell: (element: any) => `${element.lastName}`   },
      {
        columnDef: "datakind",
        header: "LISTS.DATAKIND",
        cell: (element: any) => `${element.datakind}`,
      },
      {
        columnDef: "companyname",
        header: "ADMIN.AGENCY",
        cell: (element: any) => `${element.companyname}`,
      },
    ];
  }
}

module UserService {}

export { UserService };
