
import { Injectable } from '@angular/core';
import {
    ipcRenderer,
    webFrame,
    remote,
    app,
    BrowserWindow
} from 'electron';
import * as childProcess from 'child_process';
import * as fs from 'fs';

@Injectable({
    providedIn: 'root',
})
class ElectronService {

    ipcRenderer: typeof ipcRenderer;
    webFrame: typeof webFrame;
    remote: typeof remote;
    childProcess: typeof childProcess;
    fs: typeof fs;
    isInit: boolean;
    isPublish: boolean;
    
    constructor() {}

    init()
    {
        if (window.require) {
            this.ipcRenderer = window.require('electron').ipcRenderer;
            this.webFrame = window.require('electron').webFrame;
            this.remote = window.require('electron').remote;
            this.childProcess = window.require('child_process');
            this.fs = window.require('fs');
            this.isInit = true;
            localStorage.setItem("isPublish","false");
            return;
        }
        this.isInit = false;
        return;
    }
    
    renderer()
    {
        return this.ipcRenderer;
    }
}


export {
    ElectronService
}