/**
 * Littlebigroad API
 * API du projet Littlebigroad
 *
 * OpenAPI spec version: 0.0.1
 * Contact: apiteam@littlebigroad.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export enum dataidkind {
  city = "city",
  region = "region",
  regiongroup = "regiongroup",
  country = "country",
  airport = "airport",
  hotel = "hotel",
  trip = "trip",
  agency = "agency",
  tripgabarit = "tripgabarit",
  // fillhole = "fillhole",
}

export enum datakind {
  practical = 'practical',
  touristy = 'touristy',
  picture = 'picture',
  map = 'map',
  front = 'front',
  back = 'back',
  description = 'description',
  template = 'template',
  arrangements = 'arrangements',
  renting = 'renting',
  excursion = 'excursion',
  importantcity = 'importantcity',
  train_cruise_other = 'train_cruise_other'
}

export class Relateddatakind {
  
  get = function(kind: string)
  {
    var map = {
      "city":['importantcity'],
      "region":['touristy'],
      "regiongroup":['map'],
      "country":['practical'],
      "hotel":['description'],
      "trip":['map','description','picture'],
      "agency":['front','back','renting','excursion','train_cruise_other'],
      "tripgabarit":['template'],
    };
    return map[kind];
  }
  hide = function(kind: string, field: string)
  {
    var map = {
      "langageid":
      {
        "regiongroup": true,
        "hotel": true
      },
      "country":
      {
        "hotel": true,
        "city": true,
        "step": true,
        "region": true,
        "regiongroup": true,
        "agency": true,
        "tripgabarit": true,
      }
    }
    console.log("hide",kind,field);
    if(map[field] && map[field][kind])
    {
      return map[field][kind];
    }
    return false;
  }
}

export class Associateddata {
  id: number;
  dataid: number;
  langageid?: number;
  dataidkind: dataidkind;
  datakind: datakind;
  agencyid?: number;
  folderpath: string;
  countryids?: string;
  cityid?: number;
  name?: string;
  date?: Date;
  countryid?: number;
  regionid?: number;
}

export interface Associateddata {
  id: number;
  dataid: number;
  langageid?: number;
  dataidkind: dataidkind;
  datakind: datakind;
  agencyid?: number;
  folderpath: string;
  countryids?: string;
  cityid?: number;
  name?: string;
  date?: Date;
  countryid?: number;
  regionid?: number;
}

export interface Associateddatas {
  items: Associateddata[];
  total_count: number;
}
export interface AssociateddataForCreation {
  id: number;
  dataid: number;
  langageid?: number;
  dataidkind: dataidkind;
  datakind: datakind;
  agencyid?: number;
  folderpath: string;
  countryids?: string;
  cityid?: number;
  name?: string;
  date?: Date;
  countryid?: number;
  regionid?: number;
}
export interface AssociateddataForUpdate {
  id: number;
  dataid: number;
  langageid?: number;
  dataidkind: dataidkind;
  datakind: datakind;
  agencyid?: number;
  folderpath: string;
  countryids?: string;
  cityid?: number;
  name?: string;
  date?: Date;
  countryid?: number;
  regionid?: number;
}
export interface AssociateddataForDelete {
  id: number;
  apiKey?: string;
}