import { Component, ViewEncapsulation, EventEmitter, Output} from "@angular/core";

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, fromEvent } from "rxjs";
import { filter, pluck } from "rxjs/operators";

import { AuthenticationService } from "../../_services/authentication.service";
import { StorageService } from "../../_services/storage.service";
import { StateService } from '../../_services/state.service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    // {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class HeaderComponent {

  messagePublish$: Observable<string>;
  access_role: String;
  isPublish:boolean;
  
  constructor(
    public translate: TranslateService,
    private authService: AuthenticationService,
    private router: Router,
    private dateAdapter: DateAdapter<any>,
    private storageService: StorageService,
    private stateService: StateService
  ) {
    translate.addLangs(["en", "fr"]);
    this.access_role = localStorage.getItem("access_role");
    if (localStorage.getItem("locale")) {
      const browserLang = localStorage.getItem("locale");
      translate.use(browserLang.match(/en|fr/) ? browserLang : "fr");
    } else {
      localStorage.setItem("locale", "fr");
      translate.setDefaultLang("fr");
    }
  }


  ngOnInit() {
    this.isPublish = false;
    this.messagePublish$ = this.storageService.storageChange$.pipe(
      filter(({ storageArea }) => storageArea === "sessionStorage"),
      filter(({ key }) => key === "isPublish"),
      pluck("value")
    );

    
    const subscribe = this.messagePublish$.subscribe((val) => {
      if(val=="true")
      {
        this.isPublish = true;
      }
      else
      {
        this.isPublish = false;
      }
      // console.log(val)
    });
  }

  changeLang(language: string) {
    localStorage.setItem("locale", language);
    this.translate.use(language);
    this.dateAdapter.setLocale(language);
    // this.appState.setData(language);

    this.storageService.setStorageItem({
      key: "message",
      value: "vaxxxxxxlue",
      storageArea: "sessionStorage"
    });
  }

  onLogout() {
    this.authService.doLogout();
    this.router.navigate(["/"]);
  }

  openmenu() {
    this.isopen ? (this.isopen = false) : (this.isopen = true);
    console.log("is open? ", this.isopen);
  }

  clickmenu() {
    this.isopen = false;
    console.log("is open? ", this.isopen);
    this.stateService.setState(true);
  }


  isopen: boolean = false;
}
