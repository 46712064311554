/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  Step,
  Steps,
  StepForCreation,
  StepForUpdate,
  StepForDelete,
} from "../_models/step";

@Injectable({
  providedIn: "root",
})
class StepService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all steps
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Steps>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/step?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Steps>;
      })
    );
  }

  /**
   * Returns all steps
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Steps)
    );
  }

  /**
   * Returns a single step
   * @param stepId ID of pet to return
   * @return successful operation
   */
  getStepByIdResponse(
    stepId: number
  ): __Observable<__StrictHttpResponse<Step>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/step/${stepId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Step>;
      })
    );
  }
  /**
   * Returns a single step
   * @param stepId ID of pet to return
   * @return successful operation
   */
  getStepById(stepId: number): __Observable<Step> {
    return this.getStepByIdResponse(stepId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Step)
    );
  }

  /**
   * @param params The `StepService.UpdateStepParams` containing the following parameters:
   *
   * - `stepname`: stepname of the step
   * - `name`: name of the step
   * - `email`: email of the step
   * - `password`: password of the step
   * - `companyId`: Id from the company of the step
   *
   *
   */
  createStepResponse(
    params: StepForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    if (params.kind == "hotel") {
      params.airportid = -1;
      params.airportname = "";
    } else if (params.kind == "airport") {
      params.hotelid = -1;
      params.hotelname = "";
    }
    console.log("createStepResponse",params);
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/step`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `StepService.UpdateStepParams` containing the following parameters:
   *
   * - `stepId`: ID of step that needs to be updated
   *
   * - `status`: Updated status of the step
   *
   * - `name`: Updated name of the step
   */
  updateStepResponse(
    params: StepForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    if (params.kind == "hotel") {
      params.airportid = -1;
      params.airportname = "";
    } else if (params.kind == "airport") {
      params.hotelid = -1;
      params.hotelname = "";
    }

    console.log("updateStepResponse",params);

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/step/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `StepService.UpdateStepParams` containing the following parameters:
   *
   * - `id`: ID of step that needs to be updated
   *
   * - `status`: Updated status of the step
   *
   * - `name`: Updated name of the step
   */
  updateStep(params: StepForUpdate): __Observable<null> {
    return this.updateStepResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `StepService.DeleteStepParams` containing the following parameters:
   *
   * - `id`: Step id to delete
   *
   * - `api_key`:
   */
  deleteStepResponse(
    params: StepForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/step/${params.id}`,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `StepService.DeleteStepParams` containing the following parameters:
   *
   * - `id`: Step id to delete
   *
   * - `api_key`:
   */
  deleteStep(params: StepForDelete): __Observable<null> {
    return this.deleteStepResponse(params).pipe(__map((_r) => _r.body as null));
  }

  tableService() {
    return "step";
  }

  columnsStep() {
    return [
      {
        columnDef: "id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "kind",
        header: "LISTS.TYPE_STEP",
        cell: (element: any) => `${element.kind}`,
      },
      {
        columnDef: "commandid",
        header: "LISTS.COMMAND_ID",
        cell: (element: any) => `${element.commandid}`,
      },
    ];
  }
}

module StepService {}

export { StepService };
