/* tslint:disable */
/* tslint??:disable */
export class Trip { 
  id: number;
  startidkind?: string;
  startid?: string;
  endidkind?: string;
  endid?: string;
  name?: string;
  date?: string;
}

export interface Trip {
  id: number;
  startidkind?: string;
  startid?: string;
  endidkind?: string;
  endid?: string;
  name?: string;
  date?: string;
}

export interface Trips {
  items: Trip[];
  total_count: number;
}
export interface TripForCreation {
  id: number;
  startidkind?: string;
  startid?: string;
  endidkind?: string;
  endid?: string;
  name?: string;
  date?: string;
}
export interface TripForUpdate {
    id: number;
    startidkind?: string;
    startid?: string;
    endidkind?: string;
    endid?: string;
    name?: string;
    date?: string;
  }
export interface TripForDelete {
    id: number;
    apiKey?: string;
}