import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module';
import { CommandRoutingModule } from './command-routing.module';
import { CommandComponent } from './command.component';
import { SharedModule } from '@app/_shared/shared.module';

@NgModule({
  declarations: [CommandComponent],
  imports: [
    CommonModule,
    MaterialModule,
    CommandRoutingModule,
    SharedModule
  ]
})
export class CommandModule { }
