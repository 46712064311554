import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "xxx-delete-dialog",
  templateUrl: "./delete-dialog.component.html",
  styleUrls: ["./delete-dialog.component.css"],
})
export class DeleteDialogComponent implements OnInit {
  access_role: String;
  data: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialdata: any) {
      this.data = dialdata;
    }
    
  ngOnInit(): void {

    console.log("MAT_DIALOG_DATA", this.data);
  }
}
