import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

@Injectable()
export class StateService {

  private selectionFormatState = new Subject<any>();

  setState(state: any) {
    this.selectionFormatState.next(state);
  }

  getState(): Observable<any> {
    return this.selectionFormatState.asObservable();
  }
}