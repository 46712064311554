import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'xxx-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
