import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
// import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'xxx-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommandComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    // private dateAdapter: DateAdapter<Date>
  ) {}

  ngOnInit(): void {
  }


}
