import { Component, OnInit, ChangeDetectionStrategy, Output, Input, EventEmitter, OnChanges, AfterViewInit, ViewChild } from '@angular/core';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from "@ngx-translate/core";
import { merge, of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { StateService } from '../../_services/state.service';

@Component({
  selector: 'xxx-lbr-table',
  templateUrl: './lbr-table.component.html',
  styleUrls: ['./lbr-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LbrTableComponent implements OnInit, AfterViewInit {

  access_role = localStorage.getItem('access_role');
  locale = localStorage.getItem('locale');

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  isNewState = false;

  dataSource: MatTableDataSource<any>;
  displayedColumns: any;
  filterValue = "";
  breadcrumb: String;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @Input() columns;
  @Input() itemCtxt;
  @Input() itemService;
  @Input() data: any;
  @Input() mode;

  @Output() edit = new EventEmitter<any>();
  @Output() publish = new EventEmitter<any>();
  @Output() salesforce = new EventEmitter<any>();
  @Output() report = new EventEmitter<any>();
  @Output() download = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() valid = new EventEmitter<any>();

  editItem = () => {
    // this.edit.emit(this.itemTarget)
  }

  publishItem = (event, item) => {
    if(localStorage.getItem("isPublish")=="true")
    {
      alert("Vous devez attendre la fin de la publication");
    }
    else
    {
      console.log(event, item)
      this.publish.emit([event, item])
    }
    
  }

  reportItem = (event, item) => {
    console.log(event, item)
    this.report.emit([event, item])
  }

  downloadItem = (event, item, ext, wc) => {
    console.log(event, item)
    this.download.emit([event, item, ext, wc])
  }

  salesforceItem = (event, item) => {
    console.log(event, item)
    this.salesforce.emit([event, item])
  }


  deleteItem = (event, item) => {
    console.log("deleteItem", event, item)
    this.delete.emit([event, item])
  }

  validItem = (event, item) => {
    console.log("deleteItem", event, item)
    this.valid.emit([event, item])
  }


  constructor(
    private route: ActivatedRoute, 
    public translate: TranslateService, 
    private stateService: StateService
    ) {

      this.stateService.getState().subscribe(
        currentState => {
          if(currentState==true)
          {
            this.isNewState = true;
            this.stateService.setState(false);
          }
        });
    }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      if(fragment)
      {
        this.translate.get(fragment).subscribe(val => this.breadcrumb = val);
      }
  })


    
    this.displayedColumns = this.columns.map(c => c.columnDef);
    // this.displayedColumns.unshift('action-select');
    this.displayedColumns.push('action-button');
    //paginator datasource

    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

  }

  ngOnChanges() {
    this.loadTable(true);
    // alert("ngOnChanges");
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>();
    console.log("ngAfterViewInit", this.itemService);
    // this.dataSource.paginator = this.paginator;  
    // this.paginator.firstPage();

    
    this.route.queryParams.subscribe(
      (queryParams) => {
        this.loadTable(true);
      }
    )
  }

  //fonction de filtre sur le tableau
  applyFilter(event: Event) {
    console.log("event *** ", event.target)
    this.filterValue = (event.target as HTMLInputElement).value;
    console.log(this.filterValue);
    this.loadTable(false);
    // alert("applyFilter");
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  loadTable(init) {

    
    // alert(this.paginator.pageIndex);
    let direction = null;
    let active = null;
    let pageIndex = 0;
    let pageSize = 10;
    this.isLoadingResults = true;
    console.log("loadTable PAGE **** ", this.paginator)
    if (this.sort && this.sort.direction) {
      direction = this.sort.direction;
    }
    else {
      direction = "desc";
    }
    if (this.sort && this.sort.active) {
      active = this.sort.active;
    }
    else {
      active = "id";
    }
    if (this.paginator && this.paginator.pageIndex) {
      pageIndex = this.paginator.pageIndex;
    }
    if (this.paginator && this.paginator.pageSize) {
      pageSize = this.paginator.pageSize;
    }

    console.log("loadTable",this.route.snapshot.queryParamMap);

    let search = {
      "where": [],
      "direction": direction,
      "sort": active,
      "limit": (pageIndex * pageSize) + ',' + (pageSize)
    };
    if (this.route && this.route.snapshot
      && this.route.snapshot.queryParamMap && this.route.snapshot.queryParamMap.get("where")) {
      search.where.push(this.route.snapshot.queryParamMap.get("where"));
    }
    console.log("loadTable","itemService *** ", this.itemService)
    if (this.filterValue.length > 0) {
      let columns = this.columns.map(c => c.columnDef);
      let wherelike = []
      for (let i in columns) {
        if (columns[i] == "dataidkindname" || columns[i] == "datakindname") {
          continue;
        }
        wherelike.push(/*this.itemService.tableService()+"."+*/columns[i] + " like '%" + this.filterValue + "%'");
      }
      search.where.push("(" + wherelike.join(" OR ") + ")");
    }
    this.itemService.getAll(search)
      .subscribe(data => {

        console.log("loadTable",data)
        this.dataSource = new MatTableDataSource<any>(data['items']);
        // this.dataSource.data = data['items'];

        if(this.isNewState == true)
          {
            this.paginator.firstPage();
            this.isNewState = false;
          }

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data['total_count'];
        this.paginator.length = this.resultsLength;
        console.log("loadTable","MatTableDataSourc eMatTableDataSource MatTableDataSource", this);
        // this.dataSource.paginator = this.paginator;
        if(this.dataSource && this.dataSource.paginator) {
          this.dataSource.paginator.length = this.resultsLength;
        }
        this.dataSource.sort = this.sort;
        
        
        this.sort.sortChange.subscribe(() => {
          this.paginator.pageIndex = 0;
        });

        // setTimeout(() => {
        //   if(init==true)
        //   {
        //     this.dataSource.paginator = this.paginator;  
        //     this.dataSource.sort = this.sort; 
        //     this.sort.sortChange.subscribe(() => {
        //       this.paginator.pageIndex = 0
        //     });
        //   }
        // },10);
        setTimeout(() => {
          this.paginator.length = this.resultsLength;
          
          // console.log("paginator", this.paginator.length, 'datasource', this.dataSource.paginator.length)
        }, 500);
      });
  }

  sortClicked(event) {
    console.log(event);
    this.loadTable(false);
    return false;
  }

  paginationClicked(event) {
    console.log(event, this.paginator, this.dataSource.paginator);
    this.loadTable(false);
    return false;
  }

}
