/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  Hotel,
  Hotels,
  HotelForCreation,
  HotelForUpdate,
  HotelForDelete,
} from "../_models/hotel";


@Injectable({
  providedIn: "root",
})
class HotelService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all hotels
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Hotels>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/hotel?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Hotels>;
      })
    );
  }

  /**
   * Returns all hotels
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Hotels)
    );
  }

  /**
   * Returns a single hotel
   * @param hotelId ID of pet to return
   * @return successful operation
   */
  getHotelByIdResponse(
    hotelId: number
  ): __Observable<__StrictHttpResponse<Hotel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/hotel/${hotelId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Hotel>;
      })
    );
  }
  /**
   * Returns a single hotel
   * @param hotelId ID of pet to return
   * @return successful operation
   */
  getHotelById(hotelId: number): __Observable<Hotel> {
    return this.getHotelByIdResponse(hotelId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Hotel)
    );
  }

  /**
   * @param params The `HotelService.UpdateHotelParams` containing the following parameters:
   *
   * - `hotelname`: hotelname of the hotel
   * - `name`: name of the hotel
   * - `email`: email of the hotel
   * - `password`: password of the hotel
   * - `companyId`: Id from the company of the hotel
   *
   *
   */
  createHotelResponse(
    params: HotelForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/hotel`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `HotelService.UpdateHotelParams` containing the following parameters:
   *
   * - `hotelId`: ID of hotel that needs to be updated
   *
   * - `status`: Updated status of the hotel
   *
   * - `name`: Updated name of the hotel
   */
  updateHotelResponse(
    params: HotelForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/hotel/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `HotelService.UpdateHotelParams` containing the following parameters:
   *
   * - `id`: ID of hotel that needs to be updated
   *
   * - `status`: Updated status of the hotel
   *
   * - `name`: Updated name of the hotel
   */
  updateHotel(params: HotelForUpdate): __Observable<null> {
    return this.updateHotelResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `HotelService.DeleteHotelParams` containing the following parameters:
   *
   * - `id`: Hotel id to delete
   *
   * - `api_key`:
   */
  deleteHotelResponse(
    params: HotelForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/hotel/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `HotelService.DeleteHotelParams` containing the following parameters:
   *
   * - `id`: Hotel id to delete
   *
   * - `api_key`:
   */
  deleteHotel(params: HotelForDelete): __Observable<null> {
    return this.deleteHotelResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  tableService() {
    return "hotel";
  }

  columnsHotel() {
    return [
      {
        columnDef: "hotel.id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "hotel.name",
        header: "LISTS.NAME",
        cell: (element: any) => `${element.name}`,
      },
    ];
  }
}

module HotelService {}

export { HotelService };
