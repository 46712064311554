import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material-module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClient } from "@angular/common/http";

import { SuccessDialogComponent } from "./dialogs/success-dialog/success-dialog.component";
import { ErrorDialogComponent } from "./dialogs/error-dialog/error-dialog.component";
import { DeleteDialogComponent } from "./dialogs/delete-dialog/delete-dialog.component";
import { PublishDialogComponent } from "./dialogs/publish-dialog/publish-dialog.component";
import { SalesforceDialogComponent } from "./dialogs/salesforce-dialog/salesforce-dialog.component";

import { LbrItemCardComponent } from "./lbr-item-card/lbr-item-card.component";
import { LbrTableComponent } from "./lbr-table/lbr-table.component";

import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { TranslateModule } from "@ngx-translate/core";
import { LoginDialogComponent } from './dialogs/login-dialog/login-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatAutocompleteModule,
    TranslateModule,
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    ErrorDialogComponent,
    LbrItemCardComponent,
    LbrTableComponent,
    TranslateModule,
  ],
  declarations: [ErrorDialogComponent, LbrItemCardComponent, LbrTableComponent, LoginDialogComponent],
  entryComponents: [ErrorDialogComponent],
})
export class SharedModule {}
