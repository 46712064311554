import { Component, ViewEncapsulation, Input } from '@angular/core';
import { Observable, fromEvent} from 'rxjs';
import {debounceTime, delay} from 'rxjs/operators';
import { MaterialModule } from '../../material-module';


@Component({
  selector: 'app-menu-layout',
  template: `
    <app-header></app-header>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.None 
})
export class MenuLayoutComponent {
}
