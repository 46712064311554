import { Component, OnInit, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "xxx-address-dialog",
  templateUrl: "./address-dialog.component.html",
  styleUrls: ["./address-dialog.component.css"],
})
export class AddressDialogComponent implements OnInit {
  access_role: String;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService
  ) {
    translate.addLangs(["en", "fr"]);
    this.access_role = localStorage.getItem("access_role");
    if (localStorage.getItem("locale")) {
      const browserLang = localStorage.getItem("locale");
      translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
    } else {
      localStorage.setItem("locale", "en");
      translate.setDefaultLang("en");
    }
  }
  newAddress;
  
  ngOnInit(): void {
    this.newAddress = this.data.newAddress;
  }
}
