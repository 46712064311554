import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginLayoutComponent } from './_shared/layouts/login-layout.component';
import { MenuLayoutComponent } from './_shared/layouts/menu-layout.component';

import { AuthGuard } from './_guards/auth.guard';
import { Role } from './_models/role';
import { LoginComponent } from './login';

const appRoutes: Routes = [
    {
      path: 'login',
      component: LoginLayoutComponent,
      children: [
        {
          path: '',
          component: LoginComponent
        }
      ]
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    { path: 'admin', canActivate: [AuthGuard], data: { role: [Role.Admin] }, component: MenuLayoutComponent, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) } ,
    { path: 'command', canActivate: [AuthGuard], data: { role: [Role.Admin, Role.Agency] }, component: MenuLayoutComponent, loadChildren: () => import('./command/command.module').then(m => m.CommandModule) } ,
    { path: 'list', canActivate: [AuthGuard], data: { role: [Role.Admin] }, component: MenuLayoutComponent, loadChildren: () => import('./list/list.module').then(m => m.ListModule) }
  ];

/*
const XX: Routes = [
    {
        path: 'lbr',
        component: LbrComponent,
        canActivate: [AuthGuard],
        data: { role: [Role.Admin] },
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        data: { role: [Role.Admin] }
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '', 
        redirectTo: 'lbr',
        pathMatch: 'full'
    },
    // otherwise redirect to home
    { path: '**', redirectTo: 'lbr' },
    // ...lbrRoutes
];
*/


@NgModule({
    imports: [
      RouterModule.forRoot(appRoutes, {enableTracing: false, useHash: true } )
    ],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }