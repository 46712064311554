/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  City,
  Cities,
  CityForCreation,
  CityForUpdate,
  CityForDelete,
} from "../_models/city";

@Injectable({
  providedIn: "root",
})
class CityService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all cities
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Cities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/city?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cities>;
      })
    );
  }

  /**
   * Returns all cities
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Cities)
    );
  }

  /**
   * Returns a single city
   * @param cityId ID of pet to return
   * @return successful operation
   */
  getCityByIdResponse(
    cityId: number
  ): __Observable<__StrictHttpResponse<City>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/city/${cityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<City>;
      })
    );
  }
  /**
   * Returns a single city
   * @param cityId ID of pet to return
   * @return successful operation
   */
  getCityById(cityId: number): __Observable<City> {
    return this.getCityByIdResponse(cityId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as City)
    );
  }

  /**
   * @param params The `CityService.UpdateCityParams` containing the following parameters:
   *
   * - `cityname`: cityname of the city
   * - `name`: name of the city
   * - `email`: email of the city
   * - `password`: password of the city
   * - `companyId`: Id from the company of the city
   *
   *
   */
  createCityResponse(
    params: CityForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/city`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CityService.UpdateCityParams` containing the following parameters:
   *
   * - `cityId`: ID of city that needs to be updated
   *
   * - `status`: Updated status of the city
   *
   * - `name`: Updated name of the city
   */
  updateCityResponse(
    params: CityForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/city/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CityService.UpdateCityParams` containing the following parameters:
   *
   * - `id`: ID of city that needs to be updated
   *
   * - `status`: Updated status of the city
   *
   * - `name`: Updated name of the city
   */
  updateCity(params: CityForUpdate): __Observable<null> {
    return this.updateCityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `CityService.DeleteCityParams` containing the following parameters:
   *
   * - `id`: City id to delete
   *
   * - `api_key`:
   */
  deleteCityResponse(
    params: CityForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/city/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CityService.DeleteCityParams` containing the following parameters:
   *
   * - `id`: City id to delete
   *
   * - `api_key`:
   */
  deleteCity(params: CityForDelete): __Observable<null> {
    return this.deleteCityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  tableService() {
    return "city";
  }

  columnsCity() {
    return [
      {
        columnDef: "city.id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "city.name",
        header: "LISTS.NAME",
        cell: (element: any) => `${element.name}`,
      },
    ];
  }
}

module CityService {}

export { CityService };
