/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
  throwError,
} from "rxjs";
import {
  map as __map,
  filter as __filter,
  map,
  tap,
  catchError,
} from "rxjs/operators";

import {
  Country,
  Countries,
  CountryForCreation,
  CountryForUpdate,
  CountryForDelete,
} from "../_models/country";

@Injectable({
  providedIn: "root",
})
class CountryService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all countries
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Countries>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/country?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Countries>;
      })
    );
  }

  /**
   * Returns all countries
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Countries)
    );
  }

  /**
   * Returns a single country
   * @param countryId ID of pet to return
   * @return successful operation
   */
  getCountryByIdResponse(
    countryId: number
  ): __Observable<__StrictHttpResponse<Country>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/country/${countryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Country>;
      })
    );
  }
  /**
   * Returns a single country
   * @param countryId ID of pet to return
   * @return successful operation
   */
  getCountryById(countryId: number): __Observable<Country> {
    return this.getCountryByIdResponse(countryId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Country)
    );
  }

  /**
   * @param params The `CountryService.UpdateCountryParams` containing the following parameters:
   *
   * - `countryname`: countryname of the country
   * - `name`: name of the country
   * - `email`: email of the country
   * - `password`: password of the country
   * - `countryId`: Id from the country of the country
   *
   *
   */
  createCountryResponse(
    params: CountryForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/country`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CountryService.UpdateCountryParams` containing the following parameters:
   *
   * - `countryId`: ID of country that needs to be updated
   *
   * - `status`: Updated status of the country
   *
   * - `name`: Updated name of the country
   */
  updateCountryResponse(
    params: CountryForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    // let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/country/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CountryService.UpdateCountryParams` containing the following parameters:
   *
   * - `id`: ID of country that needs to be updated
   *
   * - `status`: Updated status of the country
   *
   * - `name`: Updated name of the country
   */
  updateCountry(params: CountryForUpdate): __Observable<null> {
    return this.updateCountryResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  postCountryFile(countryId: number, file: FormData): __Observable<boolean> {
    let url = this.rootUrl + `/country/upload/` + countryId;

    let body = file;

    let req = new HttpRequest<any>(
      "POST",
      url,
      body
      // pas de header pour le formdata
    );

    return this.http.request<any>(req).pipe(
      catchError(this.handleError),
      tap((_r) => {
        console.log("RESPONSE", _r);
      }),
      __map((_r) => {
        return true;
      })
    );
  }

  /**
   * @param params The `CountryService.DeleteCountryParams` containing the following parameters:
   *
   * - `id`: Country id to delete
   *
   * - `api_key`:
   */
  deleteCountryResponse(
    params: CountryForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/country/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CountryService.DeleteCountryParams` containing the following parameters:
   *
   * - `id`: Country id to delete
   *
   * - `api_key`:
   */
  deleteCountry(params: CountryForDelete): __Observable<null> {
    return this.deleteCountryResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  tableService() {
    return "country";
  }
  columnsCountry() {
    return [
      {
        columnDef: "id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "name",
        header: "LISTS.COUNTRY",
        cell: (element: any) => `${element.name}`,
      },
    ];
  }

  private handleError(error: HttpErrorResponse) {
    let errorMsg;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
      errorMsg = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      errorMsg = error.error;
    }
    // Return an observable with a user-facing error message.
    return throwError(errorMsg);
  }
}

module CountryService {}

export { CountryService };
