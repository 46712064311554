/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import {
  StrictHttpResponse as __StrictHttpResponse,
  StrictHttpResponse,
} from "../strict-http-response";

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter, map, tap } from "rxjs/operators";

import {
  Regiongroup,
  Regiongroups,
  RegiongroupForCreation,
  RegiongroupForUpdate,
  RegiongroupForDelete,
} from "../_models/regiongroup";

@Injectable({
  providedIn: "root",
})
class RegiongroupService extends __BaseService {
  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns all countries
   */
  getAllResponse(
    search: any
  ): __Observable<__StrictHttpResponse<Regiongroups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/regiongroup?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Regiongroups>;
      })
    );
  }

  /**
   * Returns all countries
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body),
      __map((_r) => _r as Regiongroups)
    );
  }

  /**
   * Returns a single regiongroup
   * @param regiongroupId ID of pet to return
   * @return successful operation
   */
  getRegiongroupByIdResponse(
    regiongroupId: number
  ): __Observable<__StrictHttpResponse<Regiongroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/regiongroup/${regiongroupId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Regiongroup>;
      })
    );
  }
  /**
   * Returns a single regiongroup
   * @param regiongroupId ID of pet to return
   * @return successful operation
   */
  getRegiongroupById(regiongroupId: number): __Observable<Regiongroup> {
    return this.getRegiongroupByIdResponse(regiongroupId).pipe(
      __map((_r) => _r.body[0]),
      __map((_r) => _r as Regiongroup)
    );
  }

  /**
   * @param params The `RegiongroupService.UpdateRegiongroupParams` containing the following parameters:
   *
   * - `regiongroupname`: regiongroupname of the regiongroup
   * - `name`: name of the regiongroup
   * - `email`: email of the regiongroup
   * - `password`: password of the regiongroup
   * - `regiongroupId`: Id from the regiongroup of the regiongroup
   *
   *
   */
  createRegiongroupResponse(
    params: RegiongroupForCreation
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/regiongroup`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RegiongroupService.UpdateRegiongroupParams` containing the following parameters:
   *
   * - `regiongroupId`: ID of regiongroup that needs to be updated
   *
   * - `status`: Updated status of the regiongroup
   *
   * - `name`: Updated name of the regiongroup
   */
  updateRegiongroupResponse(
    params: RegiongroupForUpdate
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/regiongroup/${params.id}`,
      //__body,
      params,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RegiongroupService.UpdateRegiongroupParams` containing the following parameters:
   *
   * - `id`: ID of regiongroup that needs to be updated
   *
   * - `status`: Updated status of the regiongroup
   *
   * - `name`: Updated name of the regiongroup
   */
  updateRegiongroup(params: RegiongroupForUpdate): __Observable<null> {
    return this.updateRegiongroupResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `RegiongroupService.DeleteRegiongroupParams` containing the following parameters:
   *
   * - `id`: Regiongroup id to delete
   *
   * - `api_key`:
   */
  deleteRegiongroupResponse(
    params: RegiongroupForDelete
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/regiongroup/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RegiongroupService.DeleteRegiongroupParams` containing the following parameters:
   *
   * - `id`: Regiongroup id to delete
   *
   * - `api_key`:
   */
  deleteRegiongroup(params: RegiongroupForDelete): __Observable<null> {
    return this.deleteRegiongroupResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  tableService() {
    return "regiongroup";
  }
  columnsRegiongroup() {
    return [
      {
        columnDef: "id",
        header: "Id",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "name",
        header: "LISTS.NAME",
        cell: (element: any) => `${element.name}`,
      },
    ];
  }
}

module RegiongroupService {}

export { RegiongroupService };
