import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  Input,
  EventEmitter,
  OnChanges,
  ViewChild,
} from "@angular/core";
import {
  CountryService,
  CityService,
  RegionService,
  HotelService,
  CompanyService,
  AirportService,
  TemplateService,
  TripService,
  TemplateService as TripgabaritService,
  TemplateService as MapgabaritService,
} from "@app/_services";
import { RegiongroupService } from "@app/_services/regiongroup.service";

import { FormControl } from "@angular/forms";
import {
  debounceTime,
  filter,
  switchMap,
  finalize,
  tap,
  map,
  first,
} from "rxjs/operators";
import { ArrayDataSource } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { TranslateService } from "@ngx-translate/core";

/** Flat node with expandable and level information */
interface FileFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  isExpanded?: boolean;
}

@Component({
  selector: "xxx-lbr-item-card",
  templateUrl: "./lbr-item-card.component.html",
  styleUrls: ["./lbr-item-card.component.css"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LbrItemCardComponent implements OnInit, OnChanges {

  @Input() itemCtxt;

  @Input() itemTarget;

  @Input() searchCtrl: {
    // startairport: FormControl;
    // startcity: FormControl;
    // endairport: FormControl;
    // endcity: FormControl;
    start: FormControl;
    end: FormControl;
    airport: FormControl;
    city: FormControl;
    region: FormControl;
    regiongroup: FormControl;
    country: FormControl;
    countryGroup: FormControl;
    agency: FormControl;
    regionGroup: FormControl;
    template: FormControl;
    tripgabarit: FormControl;
    trip: FormControl;
    mapgabarit: FormControl;
  };

  @Input() searchFiltered;

  @Input() isLoading: {
    city: boolean;
    region: boolean;
    country: boolean;
    agency: boolean;
    airport: boolean;
  };

  @Input() mode;

  @Input() uploadstatus;

  @Output() update = new EventEmitter<any>();

  @Output() create = new EventEmitter<any>();

  @Output() refresh = new EventEmitter<any>();

  @Output() upload = new EventEmitter<any>();

  updateItem = () => {

    let propertiesToUpdate = {};

    for (const key in this.itemTarget) {
      if (this.itemTarget[key]) {
        propertiesToUpdate[key] = this.itemTarget[key];
      }
    }
    if (this.fileToLoad) {
      propertiesToUpdate['upload'] = this.fileToLoad;
    }

    this.update.emit(this.prepareCountriesIds(propertiesToUpdate));

    // if (Object.keys(this.fieldToUpdate).length > 0 || this.fieldToUpdate.length > 0) {
    //   this.update.emit({ itemTarget: propertiesToUpdate, specificUpdate: this.fieldToUpdate });
    // }
    // else {
    //   this.update.emit(propertiesToUpdate);
    // }
    console.debug("upload present", this.fileToLoad);

    // if (this.fileToLoad) {
    //   this.upload.emit({ itemId: this.itemTarget.id, file: this.fileToLoad });
    // }
  };

  createItem = (item) => {
    console.log("create", item, this.itemTarget, this.fieldToUpdate, this.fileToLoad);

    if (this.fileToLoad) {
      this.itemTarget.upload = this.fileToLoad;
    }

    const insertData = this.prepareCountriesIds(this.itemTarget);

    console.log("create insertData", insertData);
    
    this.create.emit(insertData);

    // if (this.fileToLoad) {
    //   this.updateItem();
    // }
  };

  prepareCountriesIds(propertiesToUpdate): Object {
    if (
      Object.keys(this.fieldToUpdate).length > 0 ||
      this.fieldToUpdate.length > 0
    ) {
      let dataUpdate = this.fieldToUpdate;
      let specificItems = Object.keys(dataUpdate);
      let newData = "";

      for (const key of specificItems) {
        //marche avec tableau et objet
        console.debug("newData specificItems", key, dataUpdate[key]);

        dataUpdate[key].forEach((element) => {
          newData += element.id + ",";
        });
      }
      //on eneleve la derniere virgule
      newData = newData.slice(0, -1);
      console.log("NEWDATA1", newData);

      //update du champs
      for (const key of specificItems) {
        propertiesToUpdate[key] = newData;
      }
      console.log("NEWDATA2", propertiesToUpdate);
      // dataUpdate.forEach(element => {
      //   newData += element.id + ","
      // });
      // newData = newData.slice(0, -1);

      // noteToUpdate.itemTarget.countryids = newData;
      // noteToUpdate = noteToUpdate.itemTarget;

      // return { itemTarget: propertiesToUpdate, specificUpdate: this.fieldToUpdate };
    }

    return propertiesToUpdate;
  }
  editAC: boolean = false;

  errorMsg: any = {};
  subFilter: any = {};

  treeControl: any;
  dataSource: any;
  treeData: FileFlatNode[];

  //isLoading: {city: boolean, region: boolean, country: boolean} = {};
  fieldToUpdate: any = {};
  services = {};

  //getter
  get overrideAgency(): boolean {
    if (this.itemTarget) {
      return this.itemTarget.agencyid ? true : false;
    } else return false;
  }
  //relation dans les tables de la BDD (utile pour faire des WHERE)
  relation = {
    agency: {
      id: "companyid",
      name: "companyname",
      table: "company",
      override: this.overrideAgency,
    },
    tripgabarit: {
      id: "tripgabaritid",
      name: "tripgabaritname",
      table: "tripgabarit",
      override: false,
    },
    mapgabarit: {
      id: "mapgabaritid",
      name: "mapgabaritname",
      table: "tripgabarit",
      override: false,
    },
    exclusivecountries: {
      id: "exclusivecountryids",
      name: "",
      table: "country",
      override: false,
    },
    startcity: {
      id: "startcityid",
      name: "",
      table: "city",
      override: true,
    },
    endcity: {
      id: "endcityid",
      name: "",
      table: "city",
      override: true,
    },
    city: {
      id: "cityid",
      name: "",
      table: "city",
      override: true,
    },
    startairport: {
      id: "startairportid",
      name: "",
      table: "airport",
      override: true,
    },
    endairport: {
      id: "endairportid",
      name: "",
      table: "airport",
      override: true,
    }
  };
  access_role: String;

  constructor(
    private hotelService: HotelService,
    private airportService: AirportService,
    private cityService: CityService,
    private regionService: RegionService,
    private regiongroupService: RegiongroupService,
    private countryService: CountryService,
    private agencyService: CompanyService,
    private tripService: TripService,
    private templateService: TemplateService // private cd: ChangeDetectorRef
  ) {
    //Création d'un objet annuaire des services
    
    this.services = {
      hotel: hotelService,
      airport: airportService,
      city: cityService,
      region: regionService,
      regiongroup: regiongroupService,
      country: countryService,
      exclusivecountries: countryService,
      agency: agencyService,
      company: agencyService,
      template: templateService,
      tripgabarit: templateService,
      trip: tripService,
      mapgabarit: templateService,
    };
  }

  hasChild = (_: number, node: FileFlatNode) => node.expandable;

  getParentNode(node: FileFlatNode) {
    const nodeIndex = this.treeData.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (this.treeData[i].level === node.level - 1) {
        return this.treeData[i];
      }
    }

    return null;
  }

  // shouldRender(node: ExampleFlatNode) {
  //   const parent = this.getParentNode(node);
  //   return !parent || parent.isExpanded;
  // }

  ngOnInit(): void {
    console.debug("ngOnInit",this.searchCtrl, this.itemTarget, this.itemCtxt);
      
    for (const key in this.searchCtrl) {
      this.searchCtrl[key].valueChanges.subscribe(
        //watcher sur les fromControles
        (data) => {
          console.debug(
            "change in",
            key,
            "with value",
            this.searchCtrl[key].value,
            "for",
            key,
            "in",
            this.searchCtrl
          );
        }
      );
    }

    for (const key in this.searchCtrl) {
      //on vérifie l'annuaire de relation
      console.log("searchCtrl",this.searchCtrl, this.relation, key);
      // if (Object.prototype.hasOwnProperty.call(this.searchCtrl, key)) {
        console.debug("searchCtrl","ngOnInit","debug KEY", key, this.relation[key], this.itemTarget);
        if (this.relation[key]) {
          let itemValue =
            this.itemTarget[this.relation[key].id] ||
            this.itemTarget[key + "id"]; // OU dans le cas Override ...
          console.debug(
            "_______ searchCtrl setSearch",
            key,
            itemValue,
            this.relation[key].table,
            key
          );
          //si on a une props qui est un id de relation avec une autre entité
          this.searchCtrl[key].patchValue(itemValue);
          console.debug("setSearch","ngOnInit#1");
          this.setSearchFromItem(itemValue, this.relation[key].table, key);

          // console.debug('assign with relation', key, this.relation[key], this.searchCtrl[key], this.itemTarget[this.relation[key].id]);
        }
        //si le nom ne correspond pas dans l'annuaire on le recompose
        else if (this.itemTarget[key + "id"]) {
          let itemValue = this.itemTarget[key + "id"];

          var nkey = key;
          switch (key) {
            case "start":
              nkey = this.itemTarget.startidkind;
              break;
            case "end":
              nkey = this.itemTarget.endidkind;
              break;
            default:
              break;
          }
          this.searchCtrl[key].patchValue(itemValue);

          // console.debug('DEBUG assign NO RELATION setSearch key', itemValue, nkey, key)
          console.debug("°°°°°°°° setSearch","ngOnInit#2", itemValue, this.itemTarget, key);
          this.setSearchFromItem(itemValue, nkey, key);
        }


        if(this.searchFiltered[key])
        {
          this.subFilter[key] = this.filterFn(key);
        }
      }
    // }

    if (this.itemTarget.dataidkind) {  
      console.debug("/////// searchCtrl setSearch","ngOnInit#3", this.itemTarget.dataidkind);
      let tableName;
      if(this.itemTarget.dataidkind=="agency")
      {
        this.setSearchFromItem(
          this.itemTarget.dataid,
          this.itemTarget.dataidkind,
          "company"
        );
      }
      else
      {
        this.setSearchFromItem(
          this.itemTarget.dataid,
          this.itemTarget.dataidkind
        );
      }
      
    }
    console.log("itemCtxt",this.itemCtxt.form);
    // ajout listener valuechange pour filtre
    for (let input of this.itemCtxt.form) {
      console.log("input filterFn type ",input.type, input.id);
      if ((input.type == "autocomplete" || input.type == "addList")) {
        console.log("input filterFn",input);
        this.subFilter[input.id] = this.filterFn(input.id);
      }
    }
    
    this.treeData = [];
    if (this.itemTarget) {
      if (this.itemTarget.associateddata) {
        for (let a in this.itemTarget.associateddata) {
          this.treeData.push({
            name: this.itemTarget.associateddata[a].folderpath,
            expandable: true,
            level: 0,
          });
          for (let f in this.itemTarget.associateddata[a].files) {
            this.treeData.push({
              name: this.itemTarget.associateddata[a].files[f],
              expandable: false,
              level: 1,
            });
          }
        }
      }
    }

    // this.treeControl = new FlatTreeControl < ExampleFlatNode > (
    //   node => node.level, node => node.expandable);
    // this.dataSource = new ArrayDataSource(this.treeData);
  }

  ngOnChanges(input): void {
    if (input.itemTarget) {
      this.itemCtxt.form.forEach((element) => {
        element.reference = this.itemTarget;
      });
    }
  }

  //refresh du filtre de recherche pour affichage des options en front
  setSearchFromItem(itemid, itemName: string, itemField?: any, tableName?: any) {

    let wFilter;
    let whereArray = [];
    // let tableName;
    itemid = parseInt(itemid);
    Number.isInteger;
    if (!isNaN(itemid)) {
      // si on override pas le comportement
      //(ex une entité avec la colonne agencyid alors que ailleurs c'est countryid ...)
      //alors on fait un appele sur le nom de l'item envoyé en arg
      if(tableName)
      {

      }
      else if (this.relation[itemName]) {
        
        tableName = this.relation[itemName].override
          ? this.relation[itemName].id
          : itemName;
          console.log("this.relation[itemName]",this.relation[itemName],tableName);

      } else {
        tableName = itemName;
        console.log("tableName",tableName);
      }
      whereArray.push(tableName + ".id=" + itemid);
    }
    console.debug("DEBUGfilter setSearch", whereArray);

    switch (itemField) {
      case "tripgabarit":
        wFilter = 'kind="trip"';
        break;
      case "trip":
        wFilter = 'kind="trip"';
        break;
      case "mapgabarit":
        wFilter = 'kind="map"';
        break;
      case "countryids":
      case "exclusivecountries":
        whereArray = [1];
        break;
      default:
        wFilter = null;
        break;
    }


    
    if (typeof itemField == "undefined") {
      itemField = itemName;
    }
    if (wFilter) {
      whereArray.push(wFilter);
    }
    console.debug("DEBUGfilter WHERE", whereArray);

    if (itemid > 0 && this.services[itemName]) {
      this.services[itemName]
        .getAll({
          where: whereArray,
        })
        .subscribe((data) => {
          if (itemid >= 0) {
            this.searchFiltered[itemField] = data.items;
            this.searchCtrl[itemField].patchValue(itemid);
            console.debug(
              "DEBUGfilter assign FORM SETTED from item " +
                itemField +
                " / " +
                itemName,
              data.items,
              this.searchCtrl,
              this.searchFiltered,
              this.searchCtrl[itemName],
              "id : " + itemid
            );
            console.debug(
              "DEBUGfilter assign FORM SETTED value ",
              itemField,
              this.searchCtrl[itemField].value
            );
            console.debug(
              "DEBUGfilter assign SEARCH SETTED value ",
              itemField,
              this.searchFiltered[itemField],
              data
            );
          }
        });
    } else {
      console.error("pas d'item ou service non disponible pour " + itemName);
    }
  }
  // ACLabel: any = {};
  // getAutoCompleteLabel(entity) {
  //   return this.searchCtrl[entity]
  // }
  // setAutoCompleteLabel(entity, value) {
  //   this.ACLabel[entity] = value
  // }
  displayFn(field: any, selector: any) {
    let list;
    // console.debug("ACDG Display Autocomplete", field, selector)
    if (this.searchFiltered[field]) {
      list = JSON.parse(JSON.stringify(this.searchFiltered[field]));
    } else {
      console.error("Champ non déclaré dans searchFiltered :", field);
    }

    console.debug("ACDG filter", this.searchFiltered);

    for (let i in list) {
      if (selector == list[i].id) {
        if (list[i].name) {
          console.debug("ACDG check name", list[i]);
          return list[i].name;
        }
        if (list[i].id) {
          return list[i].id;
        }
      }
    }
  }

  //affichage du nom de fichier pour upload
  private _uploadedFile: File;
  uploadedFile: string;

  prepareUploadFileName(uploadValue) {
    console.log("UPDATE0", uploadValue, uploadValue.files);
    this.fileToLoad = uploadValue.files[0];

    let formdata = new FormData();
    formdata.append("upfile", this.fileToLoad);
    console.log("UPDATE0", formdata.get("upfile"));
    this.uploadedFile = this.fileToLoad.name;
  }

  get fileToLoad(): File {
    return this._uploadedFile;
  }
  set fileToLoad(value: File) {
    this._uploadedFile = value;
  }

  //refresh sur changement de valeur dans input
  refreshView(event) {

    console.log("refreshView",event);
    //refresh sur changement de dataidkind
    if (event.target.id == "dataidkind" || event.target.id == "startidkind"|| event.target.id == "endidkind") {
      
      this.refresh.emit(event);
      

      if(event.target.id == "startidkind")
        {
          this.searchCtrl['start'].reset('');
          if(this.subFilter['start']){
            
            try{
              this.subFilter['start'].unsubscribe();
            }
            catch(e)
            {
              console.log(this.subFilter, this.subFilter['start'],e);
            }
          }
          this.subFilter['start'] = this.filterFn('start');
        }
        else if(event.target.id == "endidkind")
        {
          this.searchCtrl['end'].reset('');
          if(this.subFilter['end']){
            try{
              this.subFilter['end'].unsubscribe();
            }
            catch(e)
            {
              console.log(this.subFilter['end'],e);
            }
            
          }
          this.subFilter['end'] = this.filterFn('end');
        }
        
        // console.debug("event", this.subFilter);

        //ré-injection des données dans les formulaires
      this.itemCtxt.form.forEach((element) => {
        
        element.reference = this.itemTarget;

        console.debug("event", event.target.id, "element", element.id, element);

        
      });
    }
  }

  /**
   * Ajout item selectionné dans le Formcontrol addList
   * @param value int | string valeur saisie
   * @param collection array , la collection des items récupéré avec les valeurs saisies
   * @param dataType string L'identifiant de la donnée
   * @param label string le nom de la propriété de l'objet à updaté
   *
   * @return void
   */
  addItem(value, form, collection, dataType = "region", label) {

    console.log("Context", this.searchFiltered[form], this.itemCtxt);
    console.log(value, collection, dataType, label);
    
    if(!value || value==null)
    {
      return;
    }
    
    if (collection == undefined) {
      collection = [];
    }

    for(var s in collection)
    {
      if(value==collection[s]['id'])
      {
        // console.log(value+"==="+collection[s]['id']);
        return;
      }
    }

    let areaService;
    switch (dataType) {
      case "region":
        areaService = this.regionService.getRegionById(value);
        break;
      case "country":
        areaService = this.countryService.getCountryById(value);
        break;
      default:
        areaService = this.countryService.getCountryById(value);
        break;
    }

    //pour facto
    areaService.subscribe((data) => {
      collection.push(data);
      defineField(this.fieldToUpdate);
    });
    //pour updater le champ d'update spécifique
    function defineField(fieldToUpdate) {
      if (fieldToUpdate.hasOwnProperty(label)) {
        fieldToUpdate[label] = collection;
      } else {
        Object.defineProperty(fieldToUpdate, label, {
          value: collection,
          writable: true,
          enumerable: true,
        });
      }
    }
  }

  /**
   * Suppression d'un item selectionné dans le Formcontrol
   * @param event l'evenement declencheur inclut
   *
   * @return void
   */
  removeItem(elementId: number | string, collection: [], fieldToUpdate) {
    //si l'id est toujours présent dans le tableau fourni on le supprime
    collection.forEach((element: any, index) => {
      if (element.id == elementId) {
        collection.splice(index, 1);
      }
    });

    //Object contenant les champs à updater et leur valeur à traiter dans la classe correspondante
    if (this.fieldToUpdate.hasOwnProperty(fieldToUpdate)) {
      this.fieldToUpdate[fieldToUpdate] = collection;
    } else {
      Object.defineProperty(this.fieldToUpdate, fieldToUpdate, {
        value: collection,
        writable: true,
        enumerable: true,
      });
    }
  }

  /**
   * Retourne le nom du parent d'un input/FormControl
   * Utile pour faire une succession de requete et remonté les infos géo
   *
   * @param formControlName le nom du FormControl
   *
   * @return string | bool
   */
  parentGeoName(formControlName) {
    if (!formControlName) {
      return false;
    }
    // this.cd.detectChanges();
    let parentArea;
    switch (formControlName) {
      case "hotel" || "airport":
        parentArea = "city";
        break;
      case "city":
        parentArea = "region";
        break;
      case "region":
        parentArea = "country";
        break;
      default:
        parentArea = false;
        break;
    }

    return parentArea;
  }

  /**
   * affichage options de l'autocomplete selon id du parent
   *  */
  filterFn(formControlName) {


    // alert("filterFn");
    console.debug("element filterFn", formControlName, this.itemTarget);
    
    if (!formControlName) {
      return;
    }

    
    var service = formControlName;

    
    let parentArea = this.parentGeoName(formControlName);
    
    

    if (this.searchCtrl.hasOwnProperty(formControlName)) {
      
      if(this.itemTarget.startidkind && formControlName=="start")
      {
        service= this.itemTarget.startidkind;
      }
      if(this.itemTarget.endidkind && formControlName=="end")
      {
        service= this.itemTarget.endidkind;
      }
      
      console.log("formControlName",formControlName,"parentArea",parentArea,"service",service, this.itemTarget.dataid);

      if (this.itemTarget.dataid) {
        this.searchCtrl[formControlName].patchValue(this.itemTarget.dataid);
        console.log(
          "Value assign for searchCtrl",
          formControlName,
          this.searchCtrl[formControlName]
        );
      }

      let where;

      
      
      return this.searchCtrl[formControlName].valueChanges
        .pipe(
          // first(),
          tap((flux) => {
            console.debug("tap flux", formControlName, flux);
          }),
          debounceTime(100),
          filter((item) => {
            
            return typeof item == "string";
          }),
          tap((item) => {
            console.debug("tap item", formControlName, item);
            this.errorMsg[formControlName] = "";
            this.searchFiltered[formControlName] = [];
            this.isLoading[formControlName] = true;
          }),
          switchMap((value) => {
            if (value) {
            }

            value = value.toString().replace(/'/g, "\\'");
            
            var wna = service + ".name like '" + value + "%'";
            var sort = service+".name";

            switch (service) {
              case "agency":
                wna = "company" + ".name like '" + value + "%'";
                sort = "company"+".name";
                break;
              case "exclusivecountries":
                  wna = "country" + ".name like '" + value + "%'";
                  sort = "country"+".name";
                  break;
              case "trip":
                wna = "(startcity.name like '" + value + "%' OR endcity.name like '" + value + "%' OR startairport.name like '" + value + "%' OR endairport.name like '" + value + "%')";
                sort = "city"+".name";
                break;
              case "mapgabarit":
                wna =
                  "tripgabarit" + ".name like '" + value + "%' and kind='map'";
                  sort = "tripgabarit"+".name";
                break;
              case "tripgabarit":
                wna =
                  "tripgabarit" + ".name like '" + value + "%' and kind='trip'";
                break;
              default:
                break;
            }
            console.debug(formControlName, "wna", wna);
            
            where = [wna];
            
            

            if (
              this.searchCtrl[parentArea] &&
              this.searchCtrl[parentArea].value
            ) {
              var wid =
              service +
                "." +
                parentArea +
                "id =" +
                this.searchCtrl[parentArea].value;
                
              switch (service) {
                case "agency":
                  wid =
                    "company" +
                    "." +
                    parentArea +
                    "id =" +
                    this.searchCtrl[parentArea].value;
                  break;
                case "mapgabarit":
                  wid =
                    "tripgabarit" +
                    "." +
                    parentArea +
                    "id =" +
                    this.searchCtrl[parentArea].value;
                  break;
                case "trip":
                  sort = "cname";
                  break;
                    
                default:
                  break;
              }
              where.push(wid);
            }
            console.debug("switchMap", value, this.searchCtrl, where);

            


            return this.services[service]
              .getAll({
                where: where,
                direction: "asc",
                sort: sort,
                limit: 10,
              })
              .pipe(
                finalize(() => {
                  console.debug("finalize", this);
                  this.isLoading[formControlName] = false;
                })
              );
          })
        )
        .subscribe((data) => {
          
          console.log("data",data);
          if (!data || data["items"] == undefined) {
            console.debug("filter no data");
          } else if (data["items"] == undefined) {
            this.errorMsg[formControlName] = data["Error"];
            this.searchFiltered[formControlName] = [];
          } else {
            this.errorMsg[formControlName] = "";
            this.searchFiltered[formControlName] = data["items"];
            console.debug("filter data", formControlName, data["items"]);
          }
          // console.debug("searchFiltered", this.searchFiltered);
        });
        
    }
  }

  /**
   * Action lorsque la valeur de l'autocomplete change
   */
  onAutocompleteChange(selectid, event, bindTarget) {
    console.log(
      "onAutocompleteChange",
      selectid,
      event.option.value,
      bindTarget,
      this.itemTarget
    );
    this.searchCtrl[selectid].patchValue(event.option.value);
    console.debug("Value assign for searchCtrl", selectid, event.option.value);

    if (bindTarget == "data") {
      // Cas des Region Group (ou si besoin city group)
      if (this.itemTarget.data) {
        this.itemTarget.data += "," + event.option.value;
      } else {
        this.itemTarget.data = "" + event.option.value;
      }
    } else {
      console.debug(
        "CHANGE VALUE",
        this.relation[selectid],
        this.itemTarget[selectid],
        this.itemTarget, selectid
      );


      //pour les dataidkind des Step
      if (
        this.itemTarget.dataidkind &&
        selectid == this.itemTarget.dataidkind
      ) {
        this.itemTarget.dataid = event.option.value;
        console.debug("datakind", selectid, event.option.value);
      } else if (this.relation[selectid] && !this.itemTarget[bindTarget]) {
        this.itemTarget[this.relation[selectid].id] = event.option.value;
        console.debug("relation", selectid, event.option.value);
      } else {
        this.itemTarget[bindTarget] = event.option.value;
      }
    }

    console.debug("CHANGE VALUE", this.itemTarget, bindTarget);

    if(!this.services[selectid])
    {
      return;
    }

    //Preparation du Where avec switch si nom table correspond pas au nom du model front..
    let whereAC;
    switch (selectid) {
      case "agency":
        whereAC = { where: "company.id=" + parseInt(event.option.value) };
        break;
      case "exclusivecountries":
        whereAC = {
          where: "country.id=" + this.sanitize(event.option.value),
        };
        break;
      case "mapgabarit":
        whereAC = { where: "tripgabarit.id=" + parseInt(event.option.value) };
        break;
      default:
        whereAC = { where: selectid + ".id=" + parseInt(event.option.value) };
        break;
    }

    //requete

    
    this.services[selectid].getAll(whereAC).subscribe((data) => {
      console.debug("fetched", data, this.searchCtrl);

      for (const formName in this.searchCtrl) {
        if (this.parentGeoName(formName)) {
          this.searchCtrl[formName].reset();
        }

        for (const newValueProps in data.items[0]) {
          if (newValueProps == "id") {
            if (formName == selectid) {
              this.searchCtrl[selectid].patchValue(
                data.items[0][newValueProps]
              );
              console.debug(
                "assign formcontrol",
                this.searchCtrl[selectid].value,
                data.items[0][newValueProps]
              );
            } else {
              this.searchFiltered[formName] = [];
            }
          }
          //update des parents
          if (formName + "id" == newValueProps) {
            this.searchCtrl[formName].patchValue(data.items[0][newValueProps]);
            console.debug("setSearch","ngOnInit#4");
            let filter = this.setSearchFromItem(
              data.items[0][newValueProps],
              formName
            );
            console.debug(
              "assign formcontrol",
              this.searchCtrl[selectid].value,
              data.items[0][newValueProps]
            );
          }
        }
      }
    });
  }

  /**
   * Vérification si checkbox doit être checkée (CountryGroup Form)
   * @param value
   * @param collectionValues
   */
  // checkchecked(value, collectionValues) {

  //   console.debug('checking tick ', value, this.searchCtrl[value].value)
  //   if (collectionValues && value) {
  //     return collectionValues.includes(value + '');
  //   }
  //   else return false;
  // }

  /**
   * Actualise les checkboxs dans l'item pour update (CountryGroup)
   */
  updateSelectedCountries(event) {
    if (event.source.checked) {
      if (this.itemTarget.data) {
        this.itemTarget.data += "," + event.source.value;
      } else {
        this.itemTarget.data = event.source.value;
      }
    } else {
      let formersTick = this.itemTarget.data.split(",");
      let newTicked = "";
      formersTick.forEach((checkbox, index) => {
        if (Number.parseInt(checkbox) == event.source.value) {
          formersTick.splice(index, 1);
        }
        newTicked = formersTick.toString();
      });
      newTicked.trim();
      this.itemTarget.data = newTicked;
    }
  }

  /**
   * DEBUG PURPOSE
   */
  log(todebug: any) {
    console.log("debug FROM VIEW", todebug);
  }

  sanitize(expression: string) {

      expression = expression.toString().replace(";", "&#59;");
      expression = expression.replace('"', "&#1524;");
      expression = expression.replace("'", "&#145;");
      expression = expression.replace("-", "&#45;");
      expression = expression.replace("#", "&#35;");

    return expression;
  }

  debug(args: string | any[]) {
    switch (typeof args) {
      case "string":
        console.debug(args);
        break;
      case "object":
        let objectDoe = args as any;
        if (objectDoe.__proto__.isPrototypeOf([])) {
          console.debug(...args);
        }
        break;
      default:
        break;
    }
  }
}
