/* tslint:disable */
/* tslint??:disable */
export class Hotel { 
  tel?: string;
  id: number;
  name?: string;
  address?: string;
  // fax?: string;
  http?: string;
  // gps?: string;
  date: string;
  shortcut?: string;
  cityid?: number;
  zipcode?: string;
  cityname?: string;
  regionid?: number;
  regionname?: string;
  countryid?: number;
  countryname?: string;
}

export interface Hotel {
  tel?: string;
  id: number;
  name?: string;
  address?: string;
  zipcode?: string;
  cityid?: number;
  // fax?: string;
  http?: string;
  // gps?: string;
  date: string;
  shortcut?: string;
  cityname?: string;
  regionid?: number;
  regionname?: string;
  countryid?: number;
  countryname?: string;
}

export interface Hotels {
  items: Hotel[];
  total_count: number;
}
export interface HotelForCreation {
  id: number;
  cityid?: number;
  name?: string;
  date?: Date;
}
export interface HotelForUpdate {
    id: number;
    name?: string;
    city?: number;
  }
export interface HotelForDelete {
    id: number;
    apiKey?: string;
}