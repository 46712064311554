import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListComponent } from './list.component';

const routes: Routes = [{ path: '', component: ListComponent },
{ path: 'country-list', loadChildren: () => import('./country-list/country-list.module').then(m => m.CountryListModule) },
{ path: 'region-list', loadChildren: () => import('./region-list/region-list.module').then(m => m.RegionListModule) },
{ path: 'city-list', loadChildren: () => import('./city-list/city-list.module').then(m => m.CityListModule) },
{ path: 'airport-list', loadChildren: () => import('./airport-list/airport-list.module').then(m => m.AirportListModule) },
{ path: 'trip-list', loadChildren: () => import('./trip-list/trip-list.module').then(m => m.TripListModule) },
{ path: 'hotel-list', loadChildren: () => import('./hotel-list/hotel-list.module').then(m => m.HotelListModule) },
{ path: 'note-list', loadChildren: () => import('./note-list/note-list.module').then(m => m.NoteListModule) },
{ path: 'country-group-list', loadChildren: () => import('./country-group-list/country-group-list.module').then(m => m.CountryGroupListModule) },
{ path: 'region-group-list', loadChildren: () => import('./region-group-list/region-group-list.module').then(m => m.RegionGroupListModule) },
{ path: 'language-list', loadChildren: () => import('./language-list/language-list.module').then(m => m.LanguageListModule) },
{ path: 'template-list', loadChildren: () => import('./template-list/template-list.module').then(m => m.TemplateListModule) },
{ path: 'associateddata-list', loadChildren: () => import('./associateddata-list/associateddata-list.module').then(m => m.AssociateddataListModule) },
{ path: 'airport-form', loadChildren: () => import('./airport-form/airport-form.module').then(m => m.AirportFormModule) },
{ path: 'associateddata-form', loadChildren: () => import('./associateddata-form/associateddata-form.module').then(m => m.AssociateddataFormModule) },
{ path: 'country-form', loadChildren: () => import('./country-form/country-form.module').then(m => m.CountryFormModule) },
{ path: 'city-form', loadChildren: () => import('./city-form/city-form.module').then(m => m.CityFormModule) },
{ path: 'trip-form', loadChildren: () => import('./trip-form/trip-form.module').then(m => m.TripFormModule) },
{ path: 'hotel-form', loadChildren: () => import('./hotel-form/hotel-form.module').then(m => m.HotelFormModule) },
{ path: 'note-form', loadChildren: () => import('./note-form/note-form.module').then(m => m.NoteFormModule) },
{ path: 'countrygroup-form', loadChildren: () => import('./country-group-form/country-group-form.module').then(m => m.CountryGroupFormModule) },
{ path: 'langage-form', loadChildren: () => import('./language-form/language-form.module').then(m => m.LanguageFormModule) },
{ path: 'template-form', loadChildren: () => import('./template-form/template-form.module').then(m => m.TemplateFormModule) },
{ path: 'region-form', loadChildren: () => import('./region-form/region-form.module').then(m => m.RegionFormModule) },
{ path: 'regiongroup-form', loadChildren: () => import('./region-group-form/region-group-form.module').then(m => m.RegionGroupFormModule) }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ListRoutingModule { }
