/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { BaseService as __BaseService } from "../base-service";
import { ApiConfiguration as __Configuration } from "../api-configuration";
import { StrictHttpResponse as __StrictHttpResponse } from "../strict-http-response";

import { FileSaverService } from 'ngx-filesaver';
import { FileSaverOptions } from 'file-saver';

import {
  BehaviorSubject as __BehaviorSubject,
  Observable as __Observable,
} from "rxjs";
import { map as __map, filter as __filter } from "rxjs/operators";


import {
  Command,
  Commands,
  CommandForCreation,
  CommandForUpdate,
  CommandForDelete,
} from "../_models/command";
@Injectable({
  providedIn: "root",
})
class CommandService extends __BaseService {
  static readonly getCommandByIdPath = "/command/{commandId}";
  static readonly updateCommandWithFormPath = "/command/{commandId}";
  static readonly deleteCommandPath = "/command/{commandId}";
  fragment: String;

  constructor(
    config: __Configuration, 
    http: HttpClient, 
    private route: ActivatedRoute,
    private fileSaverService: FileSaverService
    ) {
    super(config, http);
    this.route.fragment.subscribe((fragment: string) => {
      // alert(111);
      if(fragment)
      {
        this.fragment = fragment;
      }
    });
  }

  /**
   * Returns all commands
   * @param void
   * @return successful operation
   */
  getAllResponse(search: any): __Observable<__StrictHttpResponse<Commands>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    var str = "";
    str +=
      search && search != null
        ? "&search=" + encodeURIComponent(JSON.stringify(search))
        : "";
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/command?` + str,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Commands>;
      })
    );
  }

  /**
   * Returns all hotels
   */
  getAll(search: any) {
    return this.getAllResponse(search).pipe(
      __map((_r) => _r.body)
      // __map(_r => _r as Commands)
    );
  }

  /**
   * Returns a single command
   * @param commandId ID of pet to return
   * @return successful operation
   */
  getCommandByIdResponse(
    commandId: number
  ): __Observable<__StrictHttpResponse<Command>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/command/${commandId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Command>;
      })
    );
  }
  /**
   * Returns a single command
   * @param commandId ID of pet to return
   * @return successful operation
   */
  getCommandById(commandId: number): __Observable<Command> {
    return this.getCommandByIdResponse(commandId).pipe(
      __map((_r) => _r.body as Command)
    );
  }

  /**
   * @param params The `CommandService.UpdateCommandWithFormParams` containing the following parameters:
   *
   * - `commandId`: ID of command that needs to be updated
   *
   * - `testarray`: Updated status of the command
   *
   * - `status`: Updated status of the command
   *
   * - `name`: Updated name of the command
   */
  updateCommandWithFormResponse(
    params: CommandService.UpdateCommandWithFormParams
  ): __Observable<__StrictHttpResponse<null>> {
    console.log("IN SERVICE!", params);
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;
    __body = params;

    // (params.testarray || []).forEach(val => { if (val != null) __formData.append('testarray', val as string | Blob) });
    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/command/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `CommandService.createCommandResponse` containing the following parameters:
   *
   * - `params`: JSON from command interface
   */
  createCommandResponse(params): __Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = params;
    // let __formData = new FormData();
    // __body = JSON.stringify(__formData);

    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }

    console.log("params in request create command", params);

    let req = new HttpRequest<any>("POST", this.rootUrl + `/command`, __body, {
      headers: __headers,
      params: __params,
      responseType: "json",
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as HttpResponse<any>;
      })
    );
  }
  /**
   * @param params The `CommandService.UpdateCommandWithFormParams` containing the following parameters:
   *
   * - `commandId`: ID of command that needs to be updated
   *
   * - `testarray`: Updated status of the command
   *
   * - `status`: Updated status of the command
   *
   * - `name`: Updated name of the command
   */
  updateCommandWithForm(
    params: CommandService.UpdateCommandWithFormParams
  ): __Observable<null> {
    console.log("updateCommandWithForm",params);
    return this.updateCommandWithFormResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `CommandService.DeleteCommandParams` containing the following parameters:
   *
   * - `commandId`: Command id to delete
   *
   * - `api_key`:
   */
  deleteCommandResponse(
    params: CommandService.DeleteCommandParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiKey != null)
      __headers = __headers.set("api_key", params.apiKey.toString());
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/command/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `CommandService.DeleteCommandParams` containing the following parameters:
   *
   * - `commandId`: Command id to delete
   *
   * - `api_key`:
   */
  deleteCommand(
    params: CommandService.DeleteCommandParams
  ): __Observable<null> {
    return this.deleteCommandResponse(params).pipe(
      __map((_r) => _r.body as null)
    );
  }

  /**
   * @param params The `CommandService.DeleteCommandParams` containing the following parameters:
   *
   * - `commandId`: Command id to delete
   *
   * - `api_key`:
   */
  validCommand(
    params: CommandService.UpdateCommandWithFormParams
  ): __Observable<__StrictHttpResponse<null>> {
    console.log("IN SERVICE!", params);
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    // let __formData = new FormData();
    // __body = __formData;
    __body = params;

    // (params.testarray || []).forEach(val => { if (val != null) __formData.append('testarray', val as string | Blob) });
    // if (params.status != null) { __formData.append('status', params.status as string | Blob); }
    // if (params.name != null) { __formData.append('name', params.name as string | Blob); }
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/command/${params.id}/valid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }


   /**
   * @param params The `CommandService.statsCommand` containing the following parameters:
   *
   * */
  top10Company(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/command/top10Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }


  /**
   * @param params The `CommandService.DeleteCommandParams` containing the following parameters:
   *
   * - `commandId`: Command id to delete
   *
   * - `api_key`:
   */
  getCommandFile(
    params: CommandService.DownloadCommandWithFormParams
  ): __Observable<__StrictHttpResponse<null>> {
    
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params;

    let url = null;
    if(params.wildcard && params.wildcard!=null && params.wildcard!="")
    {
      url = this.rootUrl + `/command/${params.command.id}/${params.wildcard}/${params.extension}/download`;
    }
    else
    {
      url = this.rootUrl + `/command/${params.command.id}/${params.extension}/download`;
    
    }

    console.log("getCommandFile",params);
    let req = new HttpRequest<any>(
      "POST",
      url,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  

  generateJSX(commandId: number): __Observable<any> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body = {};

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/command/${commandId}/formated`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>;
      })
    );
  }

  tableService() {
    return "command";
  }

  columnsCommand() {
    let cols = [
      {
        columnDef: "id",
        header: "COMMANDS.ID",
        cell: (element: any) => `${element.id}`,
      },
      {
        columnDef: "foldernumber",
        header: "COMMANDS.FOLDER_NUMBER",
        cell: (element: any) => `${element.foldernumber}`,
      },
      {
        columnDef: "deliverydate",
        header: "COMMANDS.RECAP_DELIVERY",
        type: "date",
        cell: (element: any) => `${element.deliverydate}`,
      },
      {
        columnDef: "date",
        header: "COMMANDS.DEPARTURE_DATE",
        type: "date",
        cell: (element: any) => `${element.startdate}`,
      },

    ];
    if (localStorage.getItem("access_role") == "admin") {
      cols.push({
        columnDef: "companyname",
        header: "COMMANDS.COMPANY_NAME",
        cell: (element: any) => `${element.companyname}`,
      });
      cols.push({
        columnDef: "state",
        header: "COMMANDS.STATE",
        cell: (element: any) => `${element.state}`,
      });
      // cols.push({
      //   columnDef: "is_pdf_online",
      //   header: "COMMANDS.IS_PDF",
      //   cell: (element: any) => `${element.is_pdf_online}`,
      // });

      // if(this.fragment=="MENU.PUBLISHED")
      // {
        // cols.push({
        //   columnDef: "salesforce_state",
        //   header: "COMMANDS.SF_STATE",
        //   cell: (element: any) => `${element.salesforce_state}`,
        // });
        cols.push({
          columnDef: "salesforce_quotation",
          header: "COMMANDS.SF_REF",
          cell: (element: any) => `${element.salesforce_quotation}`,
        });
      // }
      
    } else {
      cols.push({
        columnDef: "clients",
        header: "COMMANDS.CLIENTS",
        cell: (element: any) => `${element.clients}`,
      });
    }
    return cols;
  }

  onDownload(item: any): void {

    console.log("onDownload",item);
    var getFile: any = null;
    if(item.command && !item.command.id && item.command.indexOf("/")>-1)
    {
      var commandId = item.command;
      var extension = item.extension;
      var wildcard = item.wildcard;
      var xc = item.command.split("/");
      commandId = xc[1];
      getFile = {"command":{"id": commandId},"extension":extension,"wildcard":wildcard};
    }
    else
    {
      getFile = item;
    }
    console.log("onDownload getFile",getFile);
    this.getCommandFile(getFile).subscribe((result:any) => {
      
      console.log("onDownload getCommandFile",result);
      
    if (typeof(result) !== 'undefined' && typeof(result.body) !== 'undefined'  && result.body[0]!== null) {

      if(result.body.ERROR || result.body.length==0)
      {
        alert("Erreur dans le téléchargement du document.");
        return;
      }
      
        
//        window.open(fileURL, '_blank');



        let fileName = result.body[0].split("/").pop();

        this.http.get(result.body[0], {
            observe: 'response',
            responseType: 'blob'
          }).subscribe(res => {
            this.fileSaverService.save(res.body, fileName);
          });
        
      }
      else
      {
        alert("Erreur : aucun fichier disponible actuellement");
      }
    });
  }
}

module CommandService {
  /**
   * Parameters for updateCommandWithForm
   */
  export interface UpdateCommandWithFormParams {
    /**
     * ID of command that needs to be updated
     */
    id: number;

    /**
     * Updated status of the command
     */
    testarray?: Array<string>;

    /**
     * Updated status of the command
     */
    status?: string;

    /**
     * Updated name of the command
     */
    name?: string;
  }

  export interface DownloadCommandWithFormParams {
    command: any;
    extension: string;
    wildcard: string;
  }

  /**
   * Parameters for deleteCommand
   */
  export interface DeleteCommandParams {
    /**
     * Command id to delete
     */
    id: number;
    apiKey?: string;
  }
}

export { CommandService };
