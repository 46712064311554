import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CommandComponent } from './command.component';

const routes: Routes = [
  { path: '', component: CommandComponent }, 
  { path: 'command-create', loadChildren: () => import('./command-create/command-create.module').then(m => m.CommandCreateModule) }, 
  { path: 'command-details', loadChildren: () => import('./command-details/command-details.module').then(m => m.CommandDetailsModule) }, 
  { path: 'command-list', loadChildren: () => import('./command-list/command-list.module').then(m => m.CommandListModule) },
  { path: 'command-stats', loadChildren: () => import('./command-stats/command-stats.module').then(m => m.CommandStatsModule) }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommandRoutingModule { }
