/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { BehaviorSubject as __BehaviorSubject, Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../_models/user';
import { Authentication } from '../_models/authentication';
@Injectable({
  providedIn: 'root',
})
class AuthenticationService extends __BaseService {
  static readonly loginPostPath = '/login/{role}';

  private currentUserSubject: __BehaviorSubject<User>;
  public currentUser: __Observable<User>;

  constructor(
    config: __Configuration,
    http: HttpClient,
    public router: Router
  ) {
    super(config, http);
    this.currentUserSubject = new __BehaviorSubject<User>(JSON.parse(localStorage.getItem('access_user')));
    this.currentUser = this.currentUserSubject.asObservable();
    console.log("this.currentUserSubject",this.currentUserSubject,"this.currentUser",this.currentUser);
  }




  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  /**
   * Login with a particular role
   * @param params The `AuthenticationService.LoginPostParams` containing the following parameters:
   *
   * - `role`:
   *
   * - `authentication`:
   *
   * @return Success
   */
  loginPostResponse(params: AuthenticationService.LoginPostParams): __Observable<HttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    


    // __headers.set('Accept','application/json');
    // __headers.set('Content-Type','application/json');

    __body = params.authentication;
    let req = new HttpRequest<any>(
      'POST',
      // this.rootUrl + `/login/${params.role}`,
      this.rootUrl + `/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });


      // console.log("aaaaaa",req);
    //   this.http.request<any>(req).subscribe(
    //     data => {
    //        console.log("success")
    //        console.log(data)
    //     }
    //  );
  

    return this.http.request<any>(req).pipe(
      // return this.http.get(this.rootUrl + `/login/${params.role}`).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as HttpResponse<User>;
      })
    );
  }
  /**
   * Login with a particular role
   * @param params The `AuthenticationService.LoginPostParams` containing the following parameters:
   *
   * - `role`:
   *
   * - `authentication`:
   *
   * @return Success
   */
  loginPost(params: AuthenticationService.LoginPostParams): __Observable<User> {
    return this.loginPostResponse(params).pipe(
      __map((_r) => {
        // alert("loginPost -> ");
        console.log(_r);
        return _r.body as User
      })
    );
  }

  getToken() {
    return localStorage.getItem('access_token');
  }
  
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

  getRole() {
    return localStorage.getItem('access_role');
  }
  
  doLogout() {
    let removeUser = localStorage.removeItem('access_user');
    let removeToken = localStorage.removeItem('access_token');
    let removeRole = localStorage.removeItem('access_role');
    let removeLang = localStorage.removeItem('access_lang');
    let removeExlusivecountryids = localStorage.removeItem('access_exclusivecountryids');

    if (removeUser == null && removeToken == null && removeRole == null && removeLang == null && removeExlusivecountryids == null) {
      this.router.navigate(['log-in']);
    }
  }
}




module AuthenticationService {

  /**
   * Parameters for loginPost
   */
  export interface LoginPostParams {
    role: string;
    authentication: Authentication;
  }
}

export { AuthenticationService }
