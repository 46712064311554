import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { AuthenticationService } from "../_services/authentication.service";
import { LoginDialogComponent } from "@app/_shared/dialogs/login-dialog/login-dialog.component";

@Component({
  templateUrl: "login.component.html",
  styleUrls: ["login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";

  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;

  // constructor(private fb: FormBuilder, private authService: AuthenticationService) {}
  access_role: String;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {
    translate.addLangs(["en", "fr"]);
    this.access_role = localStorage.getItem("access_role");
    
    // redirect to home if already logged in
    if (this.authService.isLoggedIn) {
      this.router.navigate(["/admin/user-list"]);
    }
  }

  ngOnInit() {

    var langs = ["en", "fr"];

    var userLang = navigator.language; 

    if (localStorage.getItem("locale")) {
      const browserLang = localStorage.getItem("locale");
      this.translate.use(browserLang.match(/en|fr/) ? browserLang : "fr");
    } 
    else if(langs.indexOf(userLang)>-1)
    {
      console.log(langs[langs.indexOf(userLang)]);
      localStorage.setItem("locale", langs[langs.indexOf(userLang)]);
      this.translate.use(langs[langs.indexOf(userLang)]);
    }
    else {
      localStorage.setItem("locale", "fr");
      this.translate.setDefaultLang("fr");
    }
    
    

    this.form = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/command/command-list";
  }

  openDialog(): void {

    this.dialog.open(LoginDialogComponent,);

  }

  async onSubmit() {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;

    if (this.form.status == "VALID") {
      this.form.value.authentication = {};
      this.form.value.authentication.username = this.form.value.username;
      this.form.value.authentication.password = this.form.value.password;
      /* TODO: CHANGE IT!*/
      this.form.value.role = "admin";
      try {
        await this.authService
          .loginPost(this.form.value)
          .subscribe((res: any) => {
            localStorage.setItem("access_user", JSON.stringify(res));
            localStorage.setItem("access_token", res.token);
            localStorage.setItem("access_role", res.role);
            // localStorage.setItem("access_lang", res.commandlangageid);
            switch(res.commandlangageid)
            {
              case 1:
              default:
                localStorage.setItem("locale", "fr");
                break;
              case 2:
                localStorage.setItem("locale", "en");
                break;
            }
            var filteredcountryids = res.exclusivecountryids.split(",").filter(function (el) {
              return el != null && el != "";
            });
            localStorage.setItem("access_exclusivecountryids", filteredcountryids.join(","));

            this.router.navigate([this.returnUrl]);
          });
      } catch (err) {
        console.log(err);
        this.loginInvalid = true;
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }
}
