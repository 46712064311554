import { Component, Inject, NgZone } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.css"],
})
export class ErrorDialogComponent {
  access_role: String;
  data: any;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public dialdata: any) {
      this.data = dialdata;
    }
  
  public closeDialog = () => {

    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  };
}
