import { ApplicationRef, Component, OnInit } from "@angular/core";
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';

import { Router } from "@angular/router";
import { fromEvent, Subject } from "rxjs";

import { ElectronService } from "@app/_services/electron.service";

import { AuthenticationService, CommandService } from "./_services";
import { User, Role } from "./_models";

import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { StorageService } from "./_services/storage.service";
import { ErrorDialogComponent } from "@app/_shared/dialogs/error-dialog/error-dialog.component";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
  currentUser: User;
  renderer: any;
  statuspublish: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private commandService: CommandService,
    private electronService: ElectronService,
    public applicationRef: ApplicationRef,
    private storageService: StorageService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    console.log("AppComponent", this.authenticationService.currentUser);
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }

  ngOnInit(): void {


    this.electronService.init();

    this.storageService.setStorageItem({
      key: "isPublish",
      value: "false",
      storageArea: "sessionStorage",
    });
    console.log(this.electronService.isInit);

    if (this.electronService.isInit == true) {

      this.electronService.renderer().on("command_run", (event, arg) => {
        console.log("command_run", arg);
        this.storageService.setStorageItem({
          key: "isPublish",
          value: "true",
          storageArea: "sessionStorage",
        });
        this.applicationRef.tick();
      });

      
      this.electronService.renderer().on("command_inprogress", (event, arg) => {
        console.log("command_inprogress", arg);
        this.storageService.setStorageItem({
          key: "isPublish",
          value: "true",
          storageArea: "sessionStorage",
        });
        this.applicationRef.tick();
      });

      this.electronService.renderer().on("generate_command", (event, arg) => {
        console.log("generate_command", arg);
        this.storageService.setStorageItem({
          key: "isPublish",
          value: "true",
          storageArea: "sessionStorage",
        });
        this.applicationRef.tick();
      });

      this.electronService.renderer().on("command_done", (event, arg) => {
        console.log("command_done", arg);
        this.storageService.setStorageItem({
          key: "isPublish",
          value: "false",
          storageArea: "sessionStorage",
        });
        this.applicationRef.tick();
      });

      this.electronService.renderer().on("command_path", (event, arg) => {
        console.log("command_path", arg);
        this.storageService.setStorageItem({
          key: "isPublish",
          value: "false",
          storageArea: "sessionStorage",
        });
        this.applicationRef.tick();
        
        console.log("goto download file", arg);

        if(arg.length>0)
        {
          // this.commandService.onDownload({"command":arg,"extension":"indd"});
        }
      });

      this.electronService.renderer().on("command_error", (event, arg) => {
        console.log("command_error", arg);
        this.storageService.setStorageItem({
          key: "isPublish",
          value: "false",
          storageArea: "sessionStorage",
        });

        this.applicationRef.tick();

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        // dialogConfig.autoFocus = true;
        dialogConfig.data = JSON.parse(arg);
        const dialogRef = this.dialog.open(ErrorDialogComponent, dialogConfig);
        dialogRef.backdropClick().subscribe((result) => {
          console.log("backdropClick");
          console.log(result); // show null.
          dialogRef.close();
        });
        this.applicationRef.tick();
      });

      this.electronService.renderer().on("report_path", (event, arg) => {
        console.log("report_path", arg);
        this.applicationRef.tick();
        if(arg.length>0)
        {
          this.commandService.onDownload({"command":arg,"wildcard":"_valid","extension":"pdf"});
        }
      });

      this.electronService.renderer().on("salesforce_state", (event, arg) => {
        console.log("salesforce_state", arg);
        this.applicationRef.tick();
        if(arg.command_state==3)
        {
          this.snackBar.open(arg.command_state, "Export to Salesforce successed", {
            duration: 3000,
          });
        }
        else{
          this.snackBar.open(arg.error, "Export to Salesforce failed", {
            duration: 3000,
          });
        }
        
      });
    }
  }

  logout() {
    this.authenticationService.doLogout();
    this.router.navigate(["/login"]);
  }
}
