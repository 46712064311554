/* tslint??:disable */
export class Country { 
  id: number;
  name?: string;
}

export interface Country {
  id: number;
  name?: string;
}

export interface Countries {
  items: Country[];
  total_count: number;
}

export interface CountryForCreation {
  id: number;
  name?: string;
}
export interface CountryForUpdate {
  id: number;
  name?: string;
}
export interface CountryForDelete {
  id: number;
  apiKey?: string;
}
